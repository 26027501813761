var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "modal",
      style: { width: _vm.width, height: _vm.height, margin: "auto" },
      attrs: { id: _vm.fixeds ? "dragWindow" : "" },
      on: {
        mousedown: function ($event) {
          $event.stopPropagation()
          return _vm.startDrag.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { attrs: { id: "dragBody" } },
        [
          _vm.callModel == "2"
            ? _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: {
                    background: "#fff",
                    width: "300px",
                    "border-color": "rgb(24, 144, 255)",
                    margin: "auto",
                    color: "rgb(100 116 139)",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        "align-items": "flex-start",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { color: "rgb(100 116 139)" } },
                        [
                          _c("div", { staticClass: "row status" }, [
                            _c("span", [_vm._v("坐席状态：")]),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  color: "rgb(71 85 105)",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.autoDial
                                      ? "自动拨打中"
                                      : _vm.sipConfig.status
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm.autoDial
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                color: "rgb(100 116 139)",
                                "margin-top": "10px",
                              },
                            },
                            [
                              _c("div", { staticClass: "row status" }, [
                                _c("span", [_vm._v("当前拨打：")]),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      color: "rgb(71 85 105)",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.currentPhone))]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "row",
                          staticStyle: {
                            color: "rgb(100 116 139)",
                            width: "200px",
                            "margin-top": "10px",
                          },
                        },
                        [
                          _c("span", [_vm._v("通话状态：")]),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                color: "rgb(71 85 105)",
                              },
                            },
                            [_vm._v(_vm._s(_vm.statusMap[_vm.agentStatus]))]
                          ),
                          [
                            "IN_CALL",
                            "INCOMING_CALL",
                            "OUTGOING_CALL",
                            "HOLD",
                          ].includes(_vm.agentStatus)
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-weight": "bold",
                                    color: "rgb(71 85 105)",
                                    "margin-left": "10px",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.timerString))]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            "align-items": "center",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                            mousedown: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v("分机号:")]),
                          _c("el-input", {
                            staticClass: "phone-inputs",
                            staticStyle: {
                              "margin-left": "6px",
                              width: "140px",
                            },
                            attrs: {
                              placeholder: "请输入分机号",
                              maxlength: "4",
                              autofocus: "",
                            },
                            on: { change: _vm.callfenji },
                            model: {
                              value: _vm.call_number_four,
                              callback: function ($$v) {
                                _vm.call_number_four = $$v
                              },
                              expression: "call_number_four",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "btn",
                          staticStyle: { "margin-top": "20px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "border-radius": "30px" },
                              attrs: {
                                type: "danger",
                                icon: "el-icon-phone",
                                size: "mini",
                              },
                              on: { click: _vm.hangUps },
                            },
                            [_vm._v("挂断")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                background: "green",
                                "border-radius": "30px",
                              },
                              attrs: {
                                type: "primary",
                                icon: "el-icon-phone-outline",
                                size: "mini",
                              },
                              on: { click: _vm.answers },
                            },
                            [_vm._v("接听")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "border-radius": "30px" },
                              attrs: {
                                type: "primary",
                                icon: "el-icon-user",
                                size: "mini",
                              },
                              on: { click: _vm.login },
                            },
                            [_vm._v("登录")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.autoDialTip != ""
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            color: "green",
                            position: "static",
                            "margin-top": "10px",
                            "text-align": "center",
                            width: "100%",
                          },
                        },
                        [_vm._v(_vm._s(_vm.autoDialTip))]
                      )
                    : _vm._e(),
                  _vm.sipConfig.error && !_vm.sipConfig.calling
                    ? _c(
                        "div",
                        {
                          staticClass: "error",
                          staticStyle: {
                            position: "static",
                            "margin-top": "10px",
                          },
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                placement: "right",
                                manual: "",
                              },
                              model: {
                                value: _vm.showErrors,
                                callback: function ($$v) {
                                  _vm.showErrors = $$v
                                },
                                expression: "showErrors",
                              },
                            },
                            [
                              _c("div", {
                                attrs: { slot: "content" },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.getError(_vm.sipConfig.error)
                                  ),
                                },
                                slot: "content",
                              }),
                              _c("span", [_vm._v(_vm._s(_vm.sipConfig.error))]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.agentStatus == "INCOMING_CALL"
                    ? _c(
                        "div",
                        {
                          staticClass: "error",
                          staticStyle: {
                            color: "green",
                            position: "static",
                            "margin-top": "10px",
                          },
                        },
                        [_vm._v(" 您有来电 ")]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }