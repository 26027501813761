<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import { getToken, removeToken, checkIp } from "@/utils/auth";

let inter = null
export default {
    name: "App",
    beforeUpdate() {
        console.log(getToken());
        if (getToken()) {
            checkIp(this)
            this.$store.commit("SET_TOKEN", getToken());
            // if(inter){
            //     clearInterval(inter)
            // }
            // inter = setInterval(() =>{
            //     checkIp(this)
            // },60*1000*5)
        } else {
            this.$store.commit("SET_TOKEN", "");
            this.$store.commit("SET_ROLES", []);
            this.$store.commit("SET_PERMISSIONS", []);
            removeToken();
        }
        // this.$store.dispatch("webSocketConnect"); //初始化ws
    },
    beforeDestroy() {
        inter && clearInterval(inter)
        // this.$store.dispatch("webSocketDisconnect"); //关闭websocket
    },
};
</script>
<style lang="scss">
@font-face {
    font-family: 'Raleway';
    src:
        url('https://img.iqiyu.net/font/WixMadeforText-Medium.ttf') format('truetype'),
        url('https://img.iqiyu.net/font/WixMadeforText-Bold.ttf') format('truetype'),
        url('https://img.iqiyu.net/font/WixMadeforText-Reqular.') format('truetype');
}

body div {
    font-family: "Raleway", "pingfang SC", PingFang-Web, "微软雅黑" !important;
}

.crm-selectTre {
    span {
        width: 100%;
        text-align: center;
        height: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    span:hover {
        color: #ffffff;
        background-color: #1890ff;
    }
}

.el-select-dropdown__list .el-select-dropdown__item.hover {
    background: #46a6ff !important;
    color: #fff !important;
}

::v-deep .el-radio input[aria-hidden="true"] {
    display: none !important;
}

::v-deep .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
    box-shadow: none !important;
}
</style>
<style lang="css">
 .el-tooltip__popper {
     max-width: 50%
 }
 /* .el-table td.el-table__cell div{
    display: flex;
 } */
 img{
    display: inline-block;
 }
</style>
