/*
 * @Author: dinghao dinghao361@gmail.com
 * @Date: 2023-08-17 14:13:32
 * @LastEditors: dinghao dinghao361@gmail.com
 * @LastEditTime: 2023-08-17 23:14:21
 * @FilePath: /call_manage_system_vue/src/utils/setting.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
console.log('111', process.env.NODE_ENV);
// process.env.NODE_ENV = 'production'
// homeurl 是官网地址，  baseURL是 api请求地址  domain 是crm所在地址
if (process.env.NODE_ENV === 'production') {
    // 正式环境
    // module.exports = {
    // homeUrl: "https://www.iqiyu.net",
    // baseURL: "https://crm.iqiyu.net",
    // domain: "https://crm.iqiyu.net",
    // domainUrl: 'iqiyu.net',
    // wsUrl: "wss://crm.iqiyu.net/api/websocket/",
    // axiosBaseURL: 'https://iqiyu.net/api',
    //     aegisId: 'bR6kGf6mOYlEEG95eL',
    // noticeWsUrl: "wss://crm.iqiyu.net/api/qiYuWs",
    // }
    // qa环境
    // module.exports = {
    //     homeUrl: "https://qa.iqiyu.net",
    //     baseURL: "https://crm.iqiyu.net",
    //     domain: "https://crm.iqiyu.net",
    //     domainUrl: 'iqiyu.net',
    //     wsUrl: "wss://crm.iqiyu.net/api/websocket/",
    //     axiosBaseURL: 'https://iqiyu.net/api',
    //     aegisId: 'yroPgul0vOa8D54lxE',
    //     noticeWsUrl: "wss://crm.iqiyu.net/api/qiYuWs",
    // }
    // zjyutong.com环境
    module.exports = {
        homeUrl: "https://zjyutong.com",
        baseURL: "https://crm.iqiyu.net",
        domain: "https://crm.iqiyu.net",
        domainUrl: 'iqiyu.net',
        wsUrl: "wss://crm.iqiyu.net/api/websocket/",
        axiosBaseURL: 'https://iqiyu.net/api',
        aegisId: 'W73b5uaXwyyVKK3Y11',
        noticeWsUrl: "wss://crm.iqiyu.net/api/qiYuWs",
        webTitle: '语通',//语通配置
    }

} else if (process.env.NODE_ENV === 'test') {
    //线上测试环境
    module.exports = {
        homeUrl: "https://1qiyu.com",
        baseURL: "https://crm.1qiyu.com",
        domain: "https://crm.1qiyu.com",
        domainUrl: '1qiyu.com',
        wsUrl: "wss://crm.1qiyu.com/api/websocket/",
        axiosBaseURL: 'https://1qiyu.com/api',
        aegisId: 'O5w68uQpZ00oam2vxR',
        noticeWsUrl: "wss://crm.1qiyu.com/api/qiYuWs",
    }
} else {
    //本地环境
    // module.exports = {
    //     homeUrl: "http://localhost:8080",
    //     baseURL: "https://crm.1qiyu.com",
    //     domain: "http://192.168.2.2:8081",
    //     wsUrl: "wss://crm.1qiyu.com/api/websocket/",
    // noticeWsUrl: "wss://crm.1qiyu.com/api/qiYuWs",
    //     axiosBaseURL: 'https://1qiyu.com/api'
    // }
    module.exports = {
        homeUrl: "https://1qiyu.com",
        baseURL: "https://crm.1qiyu.com",
        domain: "https://crm.1qiyu.com",
        domainUrl: '1qiyu.com',
        wsUrl: "wss://crm.1qiyu.com/api/websocket/",
        noticeWsUrl: "wss://crm.1qiyu.com/api/qiYuWs",
        axiosBaseURL: 'https://1qiyu.com/api',
        aegisId: 'O5w68uQpZ00oam2vxR',
        webTitle: '语通',//语通配置
    }
    // module.exports = {
    // homeUrl: "https://www.iqiyu.net",
    // baseURL: "https://crm.iqiyu.net",
    // domain: "https://crm.iqiyu.net",
    // domainUrl: 'iqiyu.net',
    // wsUrl: "wss://crm.iqiyu.net/api/websocket/",
    // noticeWsUrl: "wss://crm.iqiyu.net/api/qiYuWs",
    // axiosBaseURL: 'https://iqiyu.net/api',
    //     aegisId: 'bR6kGf6mOYlEEG95eL',
    // }
    // module.exports = {
    //     homeUrl: "https://zjyutong.com",
    //     baseURL: "https://crm.zjyutong.com",
    //     domain: "https://crm.zjyutong.com",
    //     domainUrl: 'zjyutong.com',
    //     wsUrl: "wss://crm.zjyutong.com/api/websocket/",
    //     axiosBaseURL: 'https://zjyutong.com/api',
    //     aegisId: 'W73b5uaXwyyVKK3Y11',
    //     noticeWsUrl: "wss://crm.iqiyu.net/api/qiYuWs",
    //     webTitle: '语通',
    // }
    // module.exports = {
    //     homeUrl: "https://qa.iqiyu.net",
    //     baseURL: "https://crm.iqiyu.net",
    //     domain: "https://crm.iqiyu.net",
    //     domainUrl: 'iqiyu.net',
    //     wsUrl: "wss://crm.iqiyu.net/api/websocket/",
    // noticeWsUrl: "wss://crm.iqiyu.net/api/qiYuWs",
    //     axiosBaseURL: 'https://iqiyu.net/api',
    //     aegisId: 'yroPgul0vOa8D54lxE',
    // }
}