<template>
    <div :id="fixeds ? 'dragWindow' : ''" ref="modal" @mousedown.stop="startDrag"
        :style="{ width: width, height: height, margin: 'auto' }">
        <div id="dragBody">
            <el-card class="box-card" style="
                    background: #fff;
                    width: 300px;
                    border-color: rgb(24, 144, 255);
                    margin: auto;
                    color: rgb(100 116 139);
                " v-if="callModel == '2'">
                <div style="
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                    ">
                    <!-- <div class="avatar">
                        <img src="@/assets/images/kefu.png" alt="" style="width: 34px;height: 34px;">
                    </div> -->
                    <div style="color: rgb(100 116 139)">
                        <div class="row status">
                            <span>坐席状态：</span>
                            <span style="font-weight: bold; color: rgb(71 85 105)">{{ autoDial?'自动拨打中':sipConfig.status }}</span>
                        </div>
                    </div>
                    <div style="color: rgb(100 116 139);
                            margin-top: 10px;" v-if="autoDial">
                        <div class="row status">
                            <span>当前拨打：</span>
                            <span style="font-weight: bold; color: rgb(71 85 105)">{{ currentPhone }}</span>
                        </div>
                    </div>
                    <div class="row" style="
                            color: rgb(100 116 139);
                            width: 200px;
                            margin-top: 10px;
                        ">
                        <span>通话状态：</span>
                        <span style="font-weight: bold; color: rgb(71 85 105)">{{ statusMap[agentStatus] }}</span>
                        <span style="
                                font-weight: bold;
                                color: rgb(71 85 105);
                                margin-left: 10px;
                            " v-if="
                                [
                                    'IN_CALL',
                                    'INCOMING_CALL',
                                    'OUTGOING_CALL',
                                    'HOLD',
                                ].includes(agentStatus)
                            ">{{ timerString }}</span>
                    </div>
                    <div style="
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                        " @click.stop="" @mousedown.stop="">
                        <span>分机号:</span>
                        <el-input placeholder="请输入分机号" v-model="call_number_four" maxlength="4" class="phone-inputs"
                            autofocus @change="callfenji" style="margin-left: 6px; width: 140px">
                        </el-input>
                    </div>

                    <div class="btn" style="margin-top: 20px">
                        <el-button type="danger" @click="hangUps" icon="el-icon-phone" style="border-radius: 30px"
                            size="mini">挂断</el-button>
                        <el-button type="primary" @click="answers" style="background: green; border-radius: 30px"
                            icon="el-icon-phone-outline" size="mini">接听</el-button>
                        <el-button type="primary" @click="login" style="border-radius: 30px" icon="el-icon-user"
                            size="mini">登录</el-button>
                    </div>
                </div>
                <div v-if="autoDialTip != ''" style="color: green; position: static; margin-top: 10px;text-align:center;width:100%">{{autoDialTip}}</div>
                <div class="error" style="position: static; margin-top: 10px"
                    v-if="sipConfig.error && !sipConfig.calling">
                    <el-tooltip class="item" effect="dark" placement="right" v-model="showErrors" manual>
                        <div slot="content" v-html="getError(sipConfig.error)"></div>
                        <span>{{ sipConfig.error }}</span>
                    </el-tooltip>
                </div>
                <div class="error" style="color: green; position: static; margin-top: 10px"
                    v-if="agentStatus == 'INCOMING_CALL'">
                    您有来电
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import { getUserCallOutType } from "@/api/business/workNumber";

import { getUserProfile, getDeptLine, getCompanySetting } from "@/api/system/user";
import qrcall from "@/utils/qrcall.js";
import axios from "axios";

export default {
    mixins: [qrcall],
    name: "DragWindow",
    components: {},
    props: {
        fixeds: {
            type: Boolean,
            default: false,
        },
        sipPhone: {
            type: String,
            default: "",
        },
        caller: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        width: {
            type: String,
            default: "200px",
        },
        height: {
            type: String,
            default: "auto",
        },
    },
    data() {
        return {
            // 是否可以移动
            drag: false,
            autoDial:false,
            // 移动开始位置
            startPosition: {},
            // 元素当前位置
            currentPosition: {},

            // 拖拽元素
            dragHead: null,
            dragging: false,
            offsetX: 0,
            offsetY: 0,

            sipConfig: {
                status: "未登陆",
                time: "00:00:00",
                callStatus: "未通话",
                error: "",
                calling: false,
            },
            callModel: "",
            call_number_four: "",
            showErrors: true,
            jsonData: null,
            callType:''
        };
    },
    computed: {},
    created() {
        // let type = localStorage.getItem('callModel')
        // this.callModel = type ? type : '01'
        this.getUserCallOutType();
        if (this.fixeds) {
            // 初始化弹窗位置（如果需要）
            if (this.$refs.modal && this.$refs.modal.style) {
                this.$refs.modal.style.position = "fixed";
            }
        }
    },
    watch: {
        sipPhone(val) {
            this.config.extNo = this.sipPhone;
            console.log('aa',this.callModel,this.sipPhone)
            this.getUserCallOutType();
            if (this.callModel === "2" && this.sipPhone != "") {
                this.login();
            }
        },
    },
    async mounted() {
        this.config.extNo = this.sipPhone;
        console.log('aa',this.callModel,this.sipPhone)
        this.getUserCallOutType();
        if (this.callModel === "2" && this.sipPhone != "") {
            this.login();
        }
        this.getlineTypes()
        var xhr = new XMLHttpRequest();
        xhr.open("GET", "https://img.iqiyu.net/makecall_tip.json", true);
        xhr.onreadystatechange = () => {
            // 请求成功，处理返回的数据
            try {
                // console.log(xhr.responseText)
                this.jsonData = JSON.parse(xhr.responseText);
            } catch (error) {
                this.jsonData = {}
            }
        };
        xhr.send();
    },
    beforeDestroy() {
        this.logouts();
    },
    methods: {
        getError(e) {
            let json = this.jsonData;
            this.showErrors = json[e] ? true : false;

            var insertIntervalString = (
                originStr,
                disNum = 10,
                insertStr = "\n"
            ) =>
                originStr.replace(
                    new RegExp("(.{" + disNum + "})", "g"),
                    "$1" + insertStr
                );
            if (json && json[e]) {
                return insertIntervalString(
                    json[e].message + "。 " + json[e].nextStep,
                    13,
                    "<br/>"
                );
            }
        },
        async getUserCallOutType() {
            this.callModel = localStorage.getItem("callModel");
            // let res = await getUserCallOutType()

            // if (res.code == 200) {
            //     this.callModel = res.data
            // }
        },
        callfenji() {
            if (this.sendDTMF) {
                this.sendDTMF(this.call_number_four);
            }
        },
        
        async getlineTypes() {
            const res = await getDeptLine({});
            this.callType = ((res.data || []).find(x => x.isCurrent == "Y") || {}).code;
            console.log(this.callType)
        },
        startDrag(event) {
            if (!this.fixeds) return;
            // 防止默认事件（如文本选择）
            event.preventDefault();
            event.stopPropagation();
            this.dragging = true;
            // 获取鼠标点击位置相对于弹窗的偏移量
            this.offsetX = event.clientX - this.$refs.modal.offsetLeft;
            this.offsetY = event.clientY - this.$refs.modal.offsetTop;

            // 在文档上添加mousemove和mouseup事件监听器
            document.addEventListener("mousemove", this.onDrag);
            document.addEventListener("mouseup", this.stopDrag);
        },
        onDrag(event) {
            if (this.dragging) {
                // 计算新的位置
                const x = event.clientX - this.offsetX;
                const y = event.clientY - this.offsetY;
                // console.log(event, this.offsetX, this.offsetY);
                // 更新弹窗的位置（这里使用内联样式作为示例）
                this.$refs.modal.style.left = `${x}px`;
                this.$refs.modal.style.top = `${y}px`;
            }
        },
        stopDrag() {
            this.dragging = false;
            // 移除事件监听器
            document.removeEventListener("mousemove", this.onDrag);
            document.removeEventListener("mouseup", this.stopDrag);
        },
        hangUps() {
            this.hangup();
        },
        answers() {
            this.answer();
        },
    },
};
</script>

<style lang="scss" scoped>
#dragWindow {
    position: fixed;
    left: 50%;
    top: 80%;
    z-index: 999;
    transform: translate(-50%, -50%);
    // background-color: #030e06;
    color: #fff;
    border-radius: 10px;
    cursor: move;

    #dragHead {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(#34a83a, 1);
        padding: 0px 10px 0px 20px;
        box-sizing: border-box;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        position: relative;

        .dragTitle {
            font-size: 18px;
        }

        .el-icon-close {
            font-size: 18px;
            cursor: pointer;

            &:hover {
                color: #ff6347;
            }
        }
    }

    #dragBody {
        width: 100%;
        height: calc(100% - 50px);
    }
}

::v-deep .phone-inputs {
    margin-left: 38px;

    input {
        border: none !important;
        border-bottom: 1px solid #dcdfe6 !important;
        border-radius: 0 !important;
    }
}

.box-card .error {
    color: red;
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
}
</style>
