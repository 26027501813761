var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-descriptions",
            { attrs: { title: "", column: 3 } },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("span", { staticClass: "line" }),
                  _c("span", [_vm._v("个人信息")]),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "头像" } },
                [_c("userAvatar", { attrs: { user: _vm.user } })],
                1
              ),
              _c("el-descriptions-item", { attrs: { label: "账号" } }, [
                _vm._v(_vm._s(_vm.user.userName)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "公司-部门" } }, [
                _vm.user.dept
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.user.dept.deptName) +
                          " / " +
                          _vm._s(_vm.postGroup || "--")
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("el-descriptions-item", { attrs: { label: "角色" } }, [
                _vm._v(_vm._s(_vm.roleGroup || "暂无") + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "创建日期" } }, [
                _vm._v(_vm._s(_vm.user.createTime)),
              ]),
            ],
            1
          ),
          _c(
            "el-descriptions",
            {
              staticStyle: { "margin-top": "50px" },
              attrs: { title: "资料修改", column: 3, colon: false },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("span", { staticClass: "line" }),
                  _c("span", [_vm._v("资料修改")]),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "" } },
                [_c("resetPwd", { attrs: { user: _vm.user } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }