var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app-wrapper",
      class: _vm.classObj,
      style: { "--current-color": _vm.theme },
    },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      _c("sidebar", {
        staticClass: "sidebar-container",
        style: {
          backgroundColor:
            _vm.sideTheme === "theme-dark"
              ? _vm.variables.menuBg
              : _vm.variables.menuLightBg,
        },
      }),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: { hasTagsView: _vm.needTagsView },
        },
        [
          _c(
            "div",
            { class: { "fixed-header": _vm.fixedHeader } },
            [_c("navbar")],
            1
          ),
          _c("app-main"),
          _vm.showSettings ? _c("right-panel", [_c("settings")], 1) : _vm._e(),
        ],
        1
      ),
      _c("sipcall", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.showSip &&
              _vm.callModel == "2" &&
              (_vm.callType == "11" || _vm.callType == "15"),
            expression:
              "\n            showSip &&\n            callModel == '2' &&\n            (callType == '11' || callType == '15')\n        ",
          },
        ],
        ref: "sipcall",
        attrs: {
          autoDial: _vm.autoDial,
          fixeds: true,
          sipPhone: _vm.sipPhone,
          caller: _vm.caller,
        },
      }),
      _c("div", { staticClass: "myDb", on: { click: _vm.goto } }, [
        _vm.showMessage ? _c("span", { staticClass: "reds" }) : _vm._e(),
        _c("i", {
          staticClass: "el-icon-success",
          staticStyle: { "font-size": "20px", color: "#1890ff" },
        }),
        _c("span", [_vm._v("待办")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }