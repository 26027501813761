<template>
    <div class="navbar">
        <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
            @toggleClick="toggleSideBar" />
        <tags-view />
        <!-- <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!topNav" /> -->
        <!-- 顶部菜单 -->
        <top-nav id="topmenu-container" class="topmenu-container" v-if="topNav" />

        <div class="right-menu">
            <template v-if="device !== 'mobile'">
                <!-- <search id="header-search" class="right-menu-item" /> -->
            </template>

            <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="hover">
                <div style="display:flex;padding: 4px 8px;background:rgb(243 244 246);border-radius: .375rem;overflow: hidden;"> 
                    <div class="uavatar">{{ name.slice(-2) }}</div>
                    <span class="username">{{ name }}</span>
                    <!-- <vs-avatar color="rgb(59,222,200)">
                        <template #text>
                            {{ name.slice(-3) }}
                        </template>
                    </vs-avatar> -->
                </div>
                <el-dropdown-menu slot="dropdown">
                    <router-link to="/user/profile">
                        <el-dropdown-item>个人中心</el-dropdown-item>
                    </router-link>
                    <el-dropdown-item @click.native="setting = true">
                        <span>布局设置</span>
                    </el-dropdown-item>
                    <el-dropdown-item divided @click.native="logout">
                        <span>退出登录</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

            <!-- <span class="qiyu_home" @click="logout">退出登录</span> -->
        </div>
    </div>
</template>

<script>

import { setToken, getToken, removeToken } from "@/utils/auth";
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import TagsView from './TagsView/index'
import TopNav from "@/components/TopNav";
import Hamburger from "@/components/Hamburger";
import Screenfull from "@/components/Screenfull";
import SizeSelect from "@/components/SizeSelect";
import Search from "@/components/HeaderSearch";
import RuoYiGit from "@/components/RuoYi/Git";
import RuoYiDoc from "@/components/RuoYi/Doc";
import { homeUrl } from "@/utils/setting";
export default {
    components: {
        Breadcrumb,
        TagsView,
        TopNav,
        Hamburger,
        Screenfull,
        SizeSelect,
        Search,
        RuoYiGit,
        RuoYiDoc,
    },
    computed: {
        ...mapGetters(["sidebar", "user_id", "name", "avatar", "device", "socketMsgs"]),
        setting: {
            get() {
                return this.$store.state.settings.showSettings;
            },
            set(val) {
                this.$store.dispatch("settings/changeSetting", {
                    key: "showSettings",
                    value: val,
                });
            },
        },
        topNav: {
            get() {
                console.log("this.$store.state.settings.topNav;", this.$store.state.settings.topNav);
                return this.$store.state.settings.topNav;
            },
        },
    },
    beforeDestroy() {
        this.$store.dispatch('webSocketDisconnect');//关闭websocket
    },
    methods: {
        handleToQiYu() {
            window.location.href = '/register'
        },
        toggleSideBar() {
            if (window.aegis) {
                console.log('pc-点击菜单按钮')
                window.aegis.infoAll({
                    msg: 'pc-点击菜单按钮' + window.localStorage.getItem('aegisUin'),
                    ext1: '点击菜单按钮',
                    trace: 'trace',
                });
            }
            this.$store.dispatch("app/toggleSideBar");
        },
        async logout() {
            if (window.aegis) {
                console.log(window.aegis)
                console.log('手动退出需要重新登录')
                window.aegis.infoAll({
                    msg: 'pc-手动退出需要重新登录' + window.localStorage.getItem('aegisUin'),
                    ext1: 'ext',
                    trace: 'trace',
                });
            }
            console.log('是否退出登录')
            this.$store.dispatch('webSocketDisconnect');//关闭websocket
            removeToken()
            this.$store.dispatch("LogOut").then(() => {
                // location.href = "/index";
                window.location.href = '/register'
            });
        },
    },
    watch: {
        'socketMsgs': function (socketMessage) {
            console.log("navbar收到信息", socketMessage);
            let isSystemNotice = socketMessage.websocketEvent === "systemAdNotice";
            console.log("isSystemNotice", isSystemNotice, typeof (socketMessage));
            if (!isSystemNotice) {//这里只处理系统通知， 其他事件不在这里处理
                return;
            }
            // let that = this
            // 处理 count 变化的逻辑
            this.$vs.notification({
                title: socketMessage.noticeTitle,
                position: 'top-right',
                color: 'primary',
                border: "<i class='bx bx-select-multiple' ></i>",
                duration: "none",
                text: socketMessage.noticeContent,
                flat: true
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.qiyu_home {
    display: inline-block;
    padding: 0 8px;
    height: 100%;
    font-size: 16px;
    color: #303133;
    vertical-align: middle;
    margin-left: 18px;
    margin-right: 18px;
    cursor: pointer;

    &:hover {
        color: #1890ff;
    }
}

.username {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    font-size: .875rem;
    color: #303133;
    vertical-align: middle;
    cursor: pointer;
    &:hover {
        color: #1890ff;
    }
}
.uavatar{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #1890ff;
    font-size: 12px;
    color: #fff;
    display: flex;
    white-space: nowrap;
    margin-right: 8px;
    align-items: center;
    justify-content: center;
}

.navbar {
    height: 50px;
    overflow: hidden;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    user-select: none; //禁止复制

    .hamburger-container {
        line-height: 46px;
        height: 100%;
        float: left;
        cursor: pointer;
        transition: background 0.3s;
        -webkit-tap-highlight-color: transparent;

        &:hover {
            background: rgba(0, 0, 0, 0.025);
        }
    }

    .breadcrumb-container {
        float: left;
    }

    .topmenu-container {
        position: absolute;
        left: 50px;
    }

    .errLog-container {
        display: inline-block;
        vertical-align: top;
    }

    .right-menu {

        height: 100%;
        line-height: 45px;
        float: right;

        &:focus {
            outline: none;
        }

        .right-menu-item {
            display: inline-block;
            padding: 0 8px;
            height: 100%;
            font-size: 18px;
            color: #5a5e66;
            // vertical-align: text-bottom;

            &.hover-effect {
                cursor: pointer;
                transition: background 0.3s;

                &:hover {
                    background: rgba(0, 0, 0, 0.025);
                }
            }
        }

        .avatar-container {
            height: 40px;
            margin-top: 0 !important;
            // margin-right: 30px;

            // .avatar-wrapper {
            // span {
            //     vertical-align: text-bottom;
            //     margin-right: 20px;
            //     color: rgb(24, 144, 255);
            //     font-size: 1.3rem;
            // }

            // margin-top: 5px;
            // position: relative;

            .user-avatar {
                vertical-align: middle;
                cursor: pointer;
                width: 25px;
                height: 25px;
                border-radius: 10px;
            }

            .el-icon-caret-bottom {
                cursor: pointer;
                position: absolute;
                right: -20px;
                top: 25px;
                font-size: 12px;
            }

            // }
        }
    }
}
</style>
