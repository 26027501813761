var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "image-upload-multiple" },
    [
      _c(
        "el-upload",
        {
          ref: "uploader",
          class: { hide: _vm.hideUploadBtn },
          attrs: {
            "list-type": "picture-card",
            "auto-upload": _vm.autoUpload,
            disabled: _vm.disabled,
            action: _vm.action,
            name: _vm.name,
            data: _vm.data,
            "http-request": _vm.request,
            "file-list": _vm.defaultFileList,
            "show-file-list": _vm.showFileList,
            accept: _vm.accept,
            multiple: _vm.multiple,
            limit: _vm.limit,
            "before-upload": _vm.before,
            "before-remove": _vm.beforeRemove,
            "on-remove": _vm.remove,
            "on-success": _vm.success,
            "on-error": _vm.error,
            "on-preview": _vm.handlePreview,
            "on-exceed": _vm.handleExceed,
          },
          scopedSlots: _vm._u([
            {
              key: "tip",
              fn: function () {
                return [
                  _vm.tip
                    ? _c("div", { staticClass: "el-upload__tip" }, [
                        _vm._v(_vm._s(_vm.tip)),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "file",
              fn: function ({ file }) {
                return [
                  _c(
                    "div",
                    { staticClass: "image-upload-list-item" },
                    [
                      _c(
                        "el-image",
                        {
                          staticClass: "el-upload-list__item-thumbnail",
                          attrs: {
                            src: file.url,
                            fit: "cover",
                            "preview-src-list": _vm.preview,
                            "initial-index": _vm.preview.findIndex(
                              (n) => n == file.url
                            ),
                            "hide-on-click-modal": "",
                            "append-to-body": "",
                            "z-index": 9999,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "placeholder",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "image-upload-multiple-image-slot",
                                      },
                                      [_vm._v(" Loading... ")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_vm._v(" // 图片展示，正在展示加载中样式 ")]
                      ),
                      !_vm.disabled && file.status == "success"
                        ? _c(
                            "div",
                            { staticClass: "image-upload__item-actions" },
                            [
                              _vm._v(" // 右上角的移除功能 "),
                              _c(
                                "span",
                                {
                                  staticClass: "del",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove(file)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              ),
                            ]
                          )
                        : _vm._e(),
                      file.status == "ready" || file.status == "uploading"
                        ? _c(
                            "div",
                            { staticClass: "image-upload__item-progress" },
                            [
                              _vm._v(" // 正在上传的进度条 "),
                              _c("el-progress", {
                                attrs: {
                                  percentage: file.percentage,
                                  "text-inside": true,
                                  "stroke-width": 16,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("img", {
            staticClass: "ident_img",
            attrs: { src: _vm.img, alt: "上传身份证图片" },
          }),
        ]
      ),
      _c(
        "span",
        { staticStyle: { display: "none !important" } },
        [
          _vm._v(" //这里展示的是上传成功后后端返路径名 "),
          _c("el-input", {
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }