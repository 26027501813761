var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("tags-view"),
      _vm.topNav
        ? _c("top-nav", {
            staticClass: "topmenu-container",
            attrs: { id: "topmenu-container" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile" ? void 0 : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "hover" },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    padding: "4px 8px",
                    background: "rgb(243 244 246)",
                    "border-radius": ".375rem",
                    overflow: "hidden",
                  },
                },
                [
                  _c("div", { staticClass: "uavatar" }, [
                    _vm._v(_vm._s(_vm.name.slice(-2))),
                  ]),
                  _c("span", { staticClass: "username" }, [
                    _vm._v(_vm._s(_vm.name)),
                  ]),
                ]
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/user/profile" } },
                    [_c("el-dropdown-item", [_vm._v("个人中心")])],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          _vm.setting = true
                        },
                      },
                    },
                    [_c("span", [_vm._v("布局设置")])]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout.apply(null, arguments)
                        },
                      },
                    },
                    [_c("span", [_vm._v("退出登录")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }