<template>
    <div
        :class="classObj"
        class="app-wrapper"
        :style="{ '--current-color': theme }"
    >
        <div
            v-if="device === 'mobile' && sidebar.opened"
            class="drawer-bg"
            @click="handleClickOutside"
        />
        <sidebar
            class="sidebar-container"
            :style="{
                backgroundColor:
                    sideTheme === 'theme-dark'
                        ? variables.menuBg
                        : variables.menuLightBg,
            }"
        />
        <div :class="{ hasTagsView: needTagsView }" class="main-container">
            <div :class="{ 'fixed-header': fixedHeader }">
                <navbar />

                <!-- <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!topNav" /> -->
            </div>
            <app-main />
            <right-panel v-if="showSettings">
                <settings />
            </right-panel>
        </div>
        <sipcall
            ref="sipcall"
            :autoDial="autoDial"
            :fixeds="true"
            :sipPhone="sipPhone"
            :caller="caller"
            v-show="
                showSip &&
                callModel == '2' &&
                (callType == '11' || callType == '15')
            "
        ></sipcall>
        <div class="myDb" @click="goto">
            <span class="reds" v-if="showMessage"></span>
            <i class="el-icon-success" style="font-size: 20px;color:#1890ff" />
            <span>待办</span>
        </div>
    </div>
</template>

<script>
import sipcall from "@/components/sipcall";
import RightPanel from "@/components/RightPanel";
import { AppMain, Navbar, Settings, Sidebar, TagsView } from "./components";
import Breadcrumb from "@/components/Breadcrumb";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";
import variables from "@/assets/styles/variables.scss";
import { getDeptLine } from "@/api/system/user";
import {
    getCallUserPhoneList,
    getUserCallOutType,
} from "@/api/business/workNumber";
import { getCompanySetting } from "@/api/system/user";
import {myResourceList} from "@/api/business/crmResourceList"
import moment from "moment";
export default {
    name: "Layout",
    data() {
        return {
            autoDial: false,
            sipPhone: "",
            caller: "",
            callModel: "",
            callType: "",
            showSip: false,
            showMessage:false
        };
    },
    components: {
        Breadcrumb,
        AppMain,
        Navbar,
        RightPanel,
        Settings,
        Sidebar,
        TagsView,
        sipcall,
    },
    mixins: [ResizeMixin],
    computed: {
        ...mapState({
            theme: (state) => state.settings.theme,
            sideTheme: (state) => state.settings.sideTheme,
            sidebar: (state) => state.app.sidebar,
            device: (state) => state.app.device,
            showSettings: (state) => state.settings.showSettings,
            needTagsView: (state) => state.settings.tagsView,
            fixedHeader: (state) => state.settings.fixedHeader,
            user_id: (state) => state.user.user_id,
            deptId: (state) => state.user.deptId,
        }),
        classObj() {
            return {
                hideSidebar: !this.sidebar.opened,
                openSidebar: this.sidebar.opened,
                withoutAnimation: this.sidebar.withoutAnimation,
                mobile: this.device === "mobile",
            };
        },
        variables() {
            return variables;
        },
    },
    // 监听路由变化
    watch: {
        $route(to, from) {
            if (
                [
                    "/crm/CrmTaoCustomer",
                    "/call/workbench",
                    "/crm/crmSellFollow",
                    "/crm/crmTaoCustomer",
                ].includes(to.path)
            ) {
                this.showSip = true;
                this.getlineTypes();
            } else {
                this.showSip = false;
                this.$store.state.user.wsipCall.autoDial = false;
            }
        },
    },
    created() {
        console.log(this.$route.path);
        // 是否允许员工查看手机号
        getCompanySetting(this.deptId).then((response) => {
            this.$store.commit(
                "SET_CUSTOMER_PHONE",
                response.data.general_settings
                    .allow_employees_see_customer_phone
            );
        });
        getCompanySetting(this.deptId).then((response) => {
            this.$store.commit(
                "SET_COMPANYSET",
                response.data
            );
        });
        if (
            [
                "/crm/CrmTaoCustomer",
                "/call/workbench",
                "/crm/crmSellFollow",
                "/crm/crmTaoCustomer",
            ].includes(this.$route.path)
        ) {
            this.showSip = true;
            this.getlineTypes();
        } else {
            this.showSip = false;
        }
        this.getlineTypes();
        this.getlist({
            pageNum:1,
            pageSize:20,
            nextFollowStartTime:moment().format('YYYY-MM-DD') + ' 00:00:00',
            nextFollowEndTime: moment().format('YYYY-MM-DD') +' 23:59:59',	
        },'todayTodos')

    },
    methods: {
        getlist(param,key){
            myResourceList(param).then(res => {
                if(res.code == 200){
                    this.showMessage = res.rows.length > 0
                }
            })
        },
        async getlineTypes() {
            let calloutype = await getUserCallOutType();
            if (calloutype.code == 200) {
                localStorage.setItem("callModel", calloutype.data);
                this.callModel = calloutype.data;
            }
            console.log("模式：", this.callModel);
            const res = await getDeptLine({});
            this.callType = (
                (res.data || []).find((x) => x.isCurrent == "Y") || {}
            ).code;
            console.log("线路：", this.callType);

            const response = await getCallUserPhoneList({
                userId: this.user_id,
                callType: this.callType,
            });
            response.data.forEach((row) => {
                if (row.isCurrent == "Y") {
                    this.sipPhone = row.ecpNumber;
                    this.caller = row.phone;
                }
            });
            console.log("主叫：", this.sipPhone, this.caller);
            this.$nextTick(() => {
                this.$store.commit("SET_SIP", this.$refs.sipcall);
            });
        },
        handleClickOutside() {
            this.$store.dispatch("app/closeSideBar", {
                withoutAnimation: false,
            });
        },
        goto(){
            this.$router.push("/userCenter/todo")
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/mixin.scss";
@import "~@/assets/styles/variables.scss";

.app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;

    &.mobile.openSidebar {
        position: fixed;
        top: 0;
    }
}

.drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
}

.fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
}

.hideSidebar .fixed-header {
    width: calc(100% - 54px);
}

.mobile .fixed-header {
    width: 100%;
}
.myDb{
    width: 50px;
    height: 60px;
    position: fixed;
    right:0;
    top:50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    cursor: pointer;
    span{
        font-size: 12px;
    }
    .reds{
        width: 8px;
        height: 8px;
        position: absolute;
        right:8px;
        top: 8px;
        background-color: red;
        border-radius: 50%;
    }
}
</style>
