// src/utils/websocket.js
import ElementUI from 'element-ui';
import { getToken, getGroup, removeToken } from "./auth";
let websocket = null
let reconnectTimer = null
let heartCheckTimer = null

export default {
    init(url) {
        if (websocket && websocket.readyState === WebSocket.OPEN) return
        const token = getToken()
        if (!token) {
            console.error('缺少鉴权Token')
            return
        }
        websocket = new WebSocket(url + '?token=' + token)

        websocket.onopen = () => {
            console.log('WebSocket连接成功')
            // this.startHeartCheck()
            window.dispatchEvent(new Event('websocket-open')) // 全局事件通知‌:ml-citation{ref="1,3" data="citationList"}
        }

        websocket.onmessage = (e) => {
            this.handleMessage(e.data)
            // this.resetHeartCheck() // 收到消息重置心跳‌:ml-citation{ref="2,7" data="citationList"}
        }

        websocket.onerror = (e) => {
            console.error('WebSocket错误:', e)
            // this.reconnect(url)
        }

        websocket.onclose = (e) => {
            console.log('WebSocket连接关闭')
            // this.reconnect(url)
        }
    },

    // 消息统一处理
    handleMessage(data) {
        try {
            const parsedData = JSON.parse(data)
            console.log(parsedData)
            // window.dispatchEvent(new CustomEvent('websocket-message', {
            //     detail: parsedData
            // }))
            if (parsedData) {
                let item = JSON.parse(parsedData[0])
                if (item) {
                    ElementUI.Notification.info({
                        title: item.noticeTitle,
                        message: item.noticeContent
                    });
                }
            }
        } catch (e) {
            console.error('消息解析失败:', e)
        }
    },

    // 发送消息方法
    send(data) {
        if (websocket && websocket.readyState === WebSocket.OPEN) {
            const payload = typeof data === 'string' ? data : JSON.stringify(data)
            websocket.send(payload)
        } else {
            console.warn('WebSocket未连接，消息发送失败')
        }
    },

    // 心跳检测机制
    startHeartCheck() {
        // heartCheckTimer = setInterval(() => {
        //     if (websocket.readyState === WebSocket.OPEN) {
        //         this.send({ type: 'heartbeat' })
        //     }
        // }, 15000) // 15秒心跳间隔‌:ml-citation{ref="2,7" data="citationList"}
    },

    resetHeartCheck() {
        clearInterval(heartCheckTimer)
        // this.startHeartCheck()
    },

    // 断线重连
    reconnect(url) {
        clearTimeout(reconnectTimer)
        reconnectTimer = setTimeout(() => {
            console.log('尝试重新连接WebSocket...')
            this.init(url)
        }, 5000) // 5秒后重连‌:ml-citation{ref="1,2" data="citationList"}
    },

    // 主动关闭连接
    close() {
        if (websocket) {
            websocket.close()
            clearInterval(heartCheckTimer)
            clearTimeout(reconnectTimer)
        }
    }
}
