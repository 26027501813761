import axios from "axios";
import { Notification, MessageBox, Message } from "element-ui";
import store from "@/store";
import { homeUrl, baseURL } from "@/utils/setting";
import { getToken, getGroup, removeToken } from "@/utils/auth";
import Vue from "vue";
import errorCode from "@/utils/errorCode";
import router from '@/router'
// console.log('====================================');
// console.log(this,Vue);
// console.log('====================================');
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
//与后端进行数据交换的时候带上cookie
axios.defaults.withCredentials = true;
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    // 由于接口请求两个服务器 公共部分置为空
    baseURL: baseURL + process.env.VUE_APP_BASE_API,//"http://vip.netcaa.com" +
    // 超时
    timeout: 5*60*1000,
});
// request拦截器
service.interceptors.request.use(
    (config) => {
        // 是否需要设置 token
        let idAddToken = config.url.includes("/getTagList") || config.url.includes("/getPacket") || config.url.includes("/download")
        const isToken = (config.headers || {}).isToken === false;
        if (getToken() && !isToken && !idAddToken) {
            config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
            // config.headers["Cookie"] = document.cookie;
        }
        // 请求加时间戳 避免缓存问题
        if (config.method === "get") {
            config.params = config.params || {};
            config.params.timeStamp = new Date().getTime();
        }
        console.log(config)
        if (config.contentType) {
            config.headers["Content-Type"] = 'multipart/form-data';
        }
        // get请求映射params参数
        if (config.method === "get" && config.params) {
            let url = config.url + "?";
            for (const propName of Object.keys(config.params)) {
                const value = config.params[propName];
                var part = encodeURIComponent(propName) + "=";
                if (value !== null && typeof value !== "undefined") {
                    if (typeof value === "object") {
                        for (const key of Object.keys(value)) {
                            let params = propName + "[" + key + "]";
                            var subPart = encodeURIComponent(params) + "=";
                            url += subPart + encodeURIComponent(value[key]) + "&";
                        }
                    } else {
                        url += part + encodeURIComponent(value) + "&";
                    }
                }
            }
            url = url.slice(0, -1);
            config.params = {};
            config.url = url;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    (res) => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        // 获取错误信息
        const msg = errorCode[code] || res.data.msg || errorCode["default"];

        var currentPath = window.location.pathname;
        if (code !== 200 && errorCode["default"]) {
            if (window.aegis) {
                window.aegis.infoAll({
                    msg: 'pc-系统错误，请联系客服的上报错误' + window.localStorage.getItem('aegisUin'),
                    ext1: JSON.stringify(res.data),
                    trace: 'trace',
                });
            }
        }
        if (code === 401 && currentPath != '/register') {
            MessageBox.confirm(
                "登录状态已过期，您可以继续留在该页面，或者重新登录",
                "系统提示",
                {
                    confirmButtonText: "重新登录",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).then(() => {

                window.localStorage.setItem('aegisUin', null)
                store.dispatch('webSocketDisconnect');
                removeToken()
                store.dispatch("LogOut").then(() => {
                    if (window.aegis) {
                        window.aegis.infoAll({
                            msg: 'pc-接口返回401需要重新登录' + window.localStorage.getItem('aegisUin'),
                            ext1: 'ext',
                            trace: 'trace',
                        });
                    }
                    //   location.href = "/";
                    window.location.href = "/register";
                });
            });
        } else if (code === 500 && !["号码归属地信息不存在"].includes(msg)) {
            Message({
                message: msg,
                type: "error",
            });

            return Promise.reject(new Error(msg));
        } else if (code !== 200 && !["号码归属地信息不存在"].includes(msg)) {
            Notification.error({
                title: msg,
            });
            return Promise.reject("error");
        } else {
            return res.data;
        }
    },
    (error) => {
        let { message } = error;
        if (message == "Network Error") {
            //   message = "后端接口连接异常";
            message = "当前网络不稳定，请检查你的网络";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        Message({
            message: message,
            type: "error",
            duration: 5 * 1000,
        });
        return Promise.reject(error);
    }
);

export default service;
