import bus from '/src/utils/jssip/bus.js'
import {
    handleMakeCall,
    getWorkBenchData,
    sendSmsSendSingle,
    makeCallSip, makeCallsipV2
} from "@/api/call/index";

import store from '@/store'
import { canFuji } from "@/utils/business";
let rcallEndFn;//拨打结束之后回调函数
export default {
    name: 'AgentBar',
    data() {
        return {
            //初始化SDK所需要的配置
            config: {
                host: '1018sip.tongxinmao.net',
                port: '7443',
                proto: true,
                extNo: '',
                extPwd: '123456',
                autoRegister: false,
                debug: false,
                stunServer: 'stun.freeswitch.org',   //可自行修改使用stun服务器地址
                stateEventListener: this.stateEventListener
            },
            protocol: 'wss',
            //坐席分机号
            agentNo: '',
            //客户号码
            customerNo: '',
            //拨号弹窗
            showDial: false,
            //转接弹窗
            showTransferDial: false,
            //转接号码
            transNum: '',
            numList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#',],
            agentStatus: 'DISCONNECTED',
            statusMap: {
                CONNECTED: '-',
                DISCONNECTED: '-',
                REGISTERED: '-',
                UNREGISTERED: '-',
                REGISTER_FAILED: '-',
                IN_CALL: '通话中',
                INCOMING_CALL: '来电振铃',
                OUTGOING_CALL: '外呼振铃',
                HOLD: '保持中',
                CALL_END: '通话结束'
            },
            timer: null,
            timerString: '00:00:00',
            outNum: '',
            newRTCSession: null,
            sipError: {
                10001: '请求未携带X - AccessToken',
                11101: '当前ecp号码未开⼾',
                11102: '当前ecp号码处于停机状态',
                11103: '当前ECP号码当天呼叫时⻓已经达到上限',
                11104: '当前ecp号码不属于请求⽅',
                11105: 'ECP号码和主叫号码不⼀致',
                11106: '你的ECP号码尚未激活，请激活后再试',
                11107: '当前ECP号码半⼩时拨打次数达到上限',
                11108: '当前ECP号码每⽇拨打次数达到上限',
                11109: 'ECP号码⿊名单',
                11110: '当前时间段⽆法调⽤',
                11111: '通话时⻓剩余资源不⾜，⽆法发起呼叫',
                11112: '被叫⿊名单',
                11113: '被叫号码当⽇被拨打次数达到上限',
                11114: '被叫号码当⽇全平台被拨打次数达到上限',
                11115: '当前appId不属于调⽤的账号',
                11116: '当前ip不在配置的应⽤⽩名单内',
                11117: 'access token失效',
                11118: 'access token关联的app应⽤不存在',
                11119: '号码能力不够，如不能直连网关、双呼等',
                11120: '当前 ECP 号码发送短信次数达到上限',
                11121: '只允许国内号码或特定号码',
                11122: '风控限制不允许激活，如非浙江号码不允许激活、虚商号码不允许激活等',
                11123: '被叫号码不允许和主叫、ecp 号码相同',
                11124: '被叫号码校验无 H 码',
                11125: '当前企业已达呼叫上限，请稍后再试：如呼叫会话并发达到最大值',
                11130: '调⽤呼叫中⼼⻛控失败：如短时内被叫多次，hcode误等'
            },
            sipCode: {
                100: '正在尝试',
                180: '正在拨打',
                181: '正被转接',
                182: '正在排队',
                183: '通话进展',
                300: '多项选择',
                301: '被永久迁移',
                302: '被暂时迁移',
                305: '使用代理服务器',
                380: '替代服务',
                400: '呼叫不当',
                401: '未经授权：只供注册机构使用，代理服务器应使用代理服务器授权407',
                402: '要求付费（预订为将来使用)',
                403: '被禁止的',
                404: '未发现：未发现用户',
                405: '不允许的方法',
                406: '不可接受',
                407: '需要代理服务器授权',
                408: '呼叫超时：在预定时间内无法找到用户, 客户端久响没有回应',
                410: '已消失：用户曾经存在，但已从此处消失',
                413: '呼叫实体过大',
                414: '呼叫URI过长',
                415: '不支持的媒体类型',
                416: '不支持的URI方案',
                420: '不当扩展：使用了不当SIP协议扩展，服务器无法理解该扩展',
                421: '需要扩展',
                423: '时间间隔过短',
                480: '暂时不可使用',
                481: '通话 / 事务不存在',
                482: '检测到循环',
                483: '跳数过多',
                484: '地址不全',
                485: '模糊不清',
                486: '此处太忙',
                487: '呼叫被终止: 服务器呼叫超时，主动取消呼叫请求',
                488: '此处不可接受',
                491: '呼叫待批',
                493: '无法解读：无法解读S/ MIME文体部分',
                500: '服务器内部错误',
                501: '无法实施：SIP呼叫方法在此处无法实施',
                502: '不当网关',
                503: '服务不可使用',
                504: '服务器超时',
                505: '不支持该版本：服务器不支持SIP协议的这个版本',
                513: '消息过长',
                600: '各处均忙',
                603: '拒绝',
                604: '无处存在',
                606: '不可使用',
            },
            sipErrorText: '',
            first: true,
            ua: null,
            fenjihao: '',
            NextCallID: null,
            NextCallIDList: [],
            canSend: false,
            numbers: 0,
            currentPhone: '',
            autoDialTip: ''
        }
    },
    computed: {
        loginBtnDisable() {
            // return !['CONNECTED','UNREGISTERED','REGISTER_FAILED'].includes(this.agentStatus)
            return ['REGISTERED'].includes(this.agentStatus)
        },
        logoutBtnDisable() {
            return !['REGISTERED', 'CALL_END'].includes(this.agentStatus)
        },
        callBtnDisable() {
            return !['REGISTERED'].includes(this.agentStatus)
        },
        answerBtnDisable() {
            return !['INCOMING_CALL'].includes(this.agentStatus)
        },
        hangupBtnDisable() {
            return !['IN_CALL', 'HOLD', 'OUTGOING_CALL', 'INCOMING_CALL'].includes(this.agentStatus)
        },
        holdBtnDisable() {
            return !['IN_CALL'].includes(this.agentStatus)
        },
        unholdBtnDisable() {
            return !['HOLD'].includes(this.agentStatus)
        },
        transferBtnDisable() {
            return !['IN_CALL'].includes(this.agentStatus)
        },
    },
    methods: {
        onClickDialOutside(event) {
            console.log(event)
            this.showDial = false
        },
        onClickTransDialOutside(event) {
            this.showTransferDial = false;
        },
        handleAgentBarBtnClick(name) {
            if (name === 'login') {
                this.login();
            } else if (name === 'logout') {
                this.logout();
            } else if (name === 'answer') {
                this.answer();
            } else if (name === 'hangup') {
                this.hangup();
            } else if (name === 'makecall') {
                //console.info("makecall number:" + this.outNum)
                this.makeCall(this.outNum)
            } else if (name === 'hold') {
                this.hold();
            } else if (name === 'unhold') {
                this.unhold();
            } else if (name === 'transfer') {
                this.transfer(this.transNum)
            }
        },

        login() {
            if (this.protocol == 'wss') {
                this.config.proto = true
            } else {
                this.config.proto = false
            }
            console.log("config.proto:" + this.config.proto)
            this.init()
            Ctibar.register()
        },
        logouts() {
            Ctibar.unregister()
            this.websocket.close()
        },
        makeCall(phone, callEndFn) {
            this.currentPhone = phone
            if (callEndFn) {
                rcallEndFn = callEndFn
            }
            if (phone === "" || phone === undefined) {
                console.error('无效的号码，请重新输入！');
                return
            }
            function fn_Guid() {
                function s4() {
                    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                }
                return (s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4()
                );
            }
            let callId = this.caller + fn_Guid(), param;
            param = {
                sipLineType: this.callType == '11' ? 'sip' : this.callType,
                "sipCallId": callId,
                "caller": this.caller,		// 主叫
                "callee": phone,		// 被叫
                "ecpNumber": this.config.extNo	// 能力号
            }

            makeCallsipV2(param).then(res => {
                if (res.code == 200) {
                    Ctibar.makecall(phone, [`X-Next-Call-ID:${callId}`]);
                }
            })

            // if (new RegExp(/^(\d{3}-{0,1}\d{8}|\d{4}-{0,1}\d{7,8}|1{0,1}\d{10}|10086|10010|10000|\d{0,4}114|\d{6}|(9)\d{4})$/).test(this.outNum)) {
            //     Ctibar.makecall(this.outNum);
            // } else {
            //     this.$message.warning('无效的号码，请重新输入！');
            // }
        },
        sendDTMF(fenji) {
            console.log('sendDTMF')
            Ctibar.sendDTMF(fenji + '#')
        },
        hold() {
            Ctibar.hold();
        },
        unhold() {
            Ctibar.unhold();
        },
        answer() {
            Ctibar.answer();
        },
        hangup() {
            const nows = new Date();
            const year = nows.getFullYear();
            const month = nows.getMonth() + 1;
            const day = nows.getDate();
            const hour = nows.getHours();
            const minute = nows.getMinutes();
            const second = nows.getSeconds();
            window.aegis.infoAll({
                msg: 'pc-拨打电话：' + window.localStorage.getItem('aegisUin'),
                trace: 'trace',
                ext1: "挂断电话的时间：" + year + '-' + month + '-' + day + " " + hour + ':' + minute + ':' + second,
            });
            Ctibar.hangup();
        },
        transfer(phone) {
            console.info("触发转接", phone)
            Ctibar.transfer(phone);
        },
        //外呼拨号盘
        handleDialBtnClick(val) {
            this.outNum += val;
            this.$refs.outNumInput.focus();
        },
        //转接拨号盘
        handleTransDialBtnClick(val) {
            this.transNum += val;
            this.$refs.transNumInput.focus();
        },

        //参数为时间差秒数，返回这两个时间差并格式化
        computeTimeDiff(diff) {
            diff = Math.round(diff / 1000);
            let hour = Math.floor(diff / 3600).toString().padStart(2, '0');
            let min = Math.floor((diff - hour * 3600) / 60).toString().padStart(2, '0');
            let sec = (diff % 60).toString().padStart(2, '0');
            return hour + ':' + min + ':' + sec;
        },
        //重置时间
        restoreTime(origin) {
            clearInterval(this.timer);
            this.timerString = '00:00:00';
            this.timer = setInterval(() => {
                this.timerString = this.computeTimeDiff(new Date().getTime() - origin);
            }, 1000);
        },
        //状态变更回调
        stateEventListener(event, data) {
            //debug使用
            console.log('当前event为: ' + event + ', 当前data为: ' + JSON.stringify(data))

            this.agentStatus = event
            let origin = new Date().getTime();
            switch (event) {
                case "CONNECTED":
                    // this.sipConfig.status = '已登陆'
                    this.agentNo = data.localAgent
                    this.first = true
                    // this.$message.success(`您当前选择的是sipx直呼模式 能力号${this.sipPhone}登录成功`)
                    this.restoreTime(origin);
                    break;

                case "DISCONNECTED":
                    this.sipConfig.status = '未登陆'
                    this.restoreTime(origin);
                    break;

                case "UNREGISTERED":
                    this.restoreTime(origin);
                    break;

                case "OUTGOING_CALL":
                    console.log('OUTGOING_CALL')
                    this.customerNo = data.otherLegNumber;
                    this.restoreTime(origin);
                    break;

                case "INCOMING_CALL":
                    console.log('INCOMING_CALL')
                    this.customerNo = data.otherLegNumber;
                    //播放来电振铃音
                    this.playRingMedia();
                    this.restoreTime(origin);
                    break;

                case "IN_CALL":
                    this.sipConfig.callStatus = '通话中'
                    this.stopPlayRingMedia();
                    this.restoreTime(origin);
                    break;

                case "CALL_END":
                    this.sipConfig.callStatus = '通话结束'
                    this.stopPlayRingMedia();
                    //挂机铃声
                    this.playHangupMedia()
                    this.restoreTime(origin);
                    console.log("CALL_END")

                    setTimeout(() => {
                        this.NextCallID = null
                        this.canSend = false
                        this.numbers = 0
                    }, 3000);
                    break;
                default:

            }
        },

        //播放挂机铃声
        playHangupMedia() {
            const _this = this;
            var hangupAudio = document.getElementById("hangupMediaAudioId")
            if (!hangupAudio) {
                hangupAudio = document.createElement('audio');
                hangupAudio.id = 'hangupMediaAudioId';
                hangupAudio.hidden = true;
                hangupAudio.src = 'https://img.iqiyu.net/sip_source/hangup.wav'
                document.body.appendChild(hangupAudio);
            }
            if (hangupAudio) {
                hangupAudio.play();
            }
        },
        //播放来电振铃
        playRingMedia() {
            const _this = this;
            _this.stopPlayRingMedia();

            var ringAudio = document.getElementById("ringMediaAudioId")
            if (!ringAudio) {
                ringAudio = document.createElement('audio');
                ringAudio.id = 'ringMediaAudioId';
                ringAudio.hidden = true;
                ringAudio.src = 'https://img.iqiyu.net/sip_source/ring.wav';
                ringAudio.loop = 'loop';
                document.body.appendChild(ringAudio);
            }
            if (ringAudio) {
                ringAudio.play();
            }
        },
        //停止播放来电振铃
        stopPlayRingMedia() {
            const _this = this;
            var ringAudio = document.getElementById("ringMediaAudioId");
            if (ringAudio) {
                document.body.removeChild(ringAudio);
            }
        },
        //初始化方法
        init() {
            function sipToJson(sipMessage) {
                // 解析请求行
                const requestLine = sipMessage.split('\n')[0];
                const [method, requestURI, version] = requestLine.split(' ');

                // 解析头部字段
                const headers = {};
                sipMessage.split('\n').forEach(line => {
                    const [key, ...value] = line.split(':');
                    headers[key] = value.join(':').trim();
                });

                // 解析内容（如果存在）
                let content;
                if (headers['Content-Length']) {
                    content = sipMessage.split('\n').slice(headers['Content-Length']).join('').trim();
                }

                // 创建JSON对象
                const json = {
                    method,
                    requestURI,
                    version,
                    headers,
                    content
                };

                return json;
            }
            function hasKey(obj, key) {
                if (key !== undefined && key !== NaN && key !== null && key !== '') {
                    let keys = Object.keys(obj)
                    return keys.includes(key + '')
                } else {
                    return false
                }
            }
            let res = Ctibar.initSDK(this.config)
            this.websocket = res.f._ws
            this.ua = res.ua
            // 当接收到消息时执行
            this.websocket.addEventListener('message', (event) => {
                let { data } = event, message = data.split(' '), errors = '';
                let sipJson = sipToJson(event.data), { CSeq } = sipJson.headers
                let status = sipJson.requestURI, code = null
                console.log('收到消息: ', sipJson, typeof status);


                if (this.first && status == '200') {
                    this.first = false
                    this.sipConfig.status = '已登陆'
                    this.$message.success(`您当前选择的是sipx直呼模式 能力号${this.sipPhone}登录成功`)
                }
                if (this.first && status == '403') {
                    this.first = false
                    this.sipConfig.status = '未登陆'
                    this.$message.error(`您当前选择的是sipx直呼模式 能力号${this.sipPhone}登录失败`)
                }
                if (status && hasKey(this.sipCode, status) && ['400', '404', '408', '410', '414', '420', '484', '487', '500', '504', '505'].includes(status)) {
                    let statuss = Number(status)
                    errors = this.sipCode[statuss]
                }
                if (sipJson.headers['X-Reason']) {
                    code = sipJson.headers['X-Reason'].replace('subcode=', '')
                    code = Number(code)
                    console.log(code)
                    if (hasKey(this.sipError, code)) {
                        errors = this.sipError[code]
                    }
                }

                /*cseg参数包含两个部分国第一部分表示invite的序号。来区分上一次请求和下一次请求第二部分表示请求方法。 111 invite 列应111invite 不对应111 register 不对度 112 invite需要设置两个变量 
                1 cseg(来确定是对应的呼叫invite请求〕 
                2 callid 默认值为空每次新打电话 invite 控制台输出on new rtcsession的时候。ocal”表明是在主动外呼而不是接听,。判断method为"INVITE”然后存储cseg 初始化callid团TOr101natOr:以下每次接到消息都需要去判断cseg是否包含"invite”方法名字2月斯保县城杏昌苑18批消县成、判浙cse0是否等于第一步存道的c560月斯山i是否为电加果力表用这是幕一次6到18消息、存像山i的鱼。加果cali不为空、表用这是第二次0318消息该种清发0座三品在北联只不泛等待客户楼听的情况。这个矿就票要调用p8(ec如果监听到200的消息 那么这个时候表明客户接听电话。调用makecallsip接口 并且cseg 和callid 恢复初始化如果姐听到603消息,表明拨打出现问题，不调用makecallsip接口。 并且cseq 和callid 恢复初始化
                */
                // if (CSeq.indexOf('INVITE') > -1 && status && status.length < 6) {
                //     if (status == '183') {
                //         let callId = sipJson.headers['Next-Call-ID']
                //         if (callId && this.NextCallIDList.indexOf(callId) == -1) {
                //             this.canSend = true
                //             console.log(this.canSend, 'canSend')
                //             if (this.numbers == 1) {
                //                 this.NextCallIDList.push(this.NextCallID)
                //                 //说明是第二次
                //                 makeCallSip({ "sipCallId": this.NextCallID })
                //                 this.NextCallID = null
                //                 this.canSend = false
                //                 this.numbers = 0
                //                 console.log(this.numbers, '第二次')
                //             } else {
                //                 this.numbers++
                //             }
                //         }
                //         if (callId) {
                //             this.NextCallID = sipJson.headers['Next-Call-ID']
                //         }
                //     } else {
                //         if (status == '200') {
                //             this.canSend = true
                //         } else if (code || ['603', '487', '408'].indexOf(status) == -1) {
                //             this.canSend = false
                //         }
                //         console.log(this.canSend, CSeq, status, ['603', '487', '408'].indexOf(status) == -1, code)
                //         if (this.NextCallID && this.canSend && this.NextCallIDList.indexOf(this.NextCallID) == -1) {
                //             this.NextCallIDList.push(this.NextCallID)
                //             //说明是第二次
                //             makeCallSip({ "sipCallId": this.NextCallID })
                //             this.NextCallID = null
                //             this.canSend = false
                //             this.numbers = 0
                //         }
                //     }
                // }
                window.aegis.infoAll({
                    msg: 'pc-sip电话日志,手机号：' + window.localStorage.getItem('aegisUin'),
                    ext1: 'code:' + status + ' / errors:' + errors + ' / CSeq:' + sipJson.headers.CSeq + ' / Next-Call-ID:' + sipJson.headers['Next-Call-ID'] + ' / X-Reason:' + sipJson.headers['X-Reason'],
                    ext2: 'method:' + sipJson.method + ' / To:' + sipJson.headers['To'] + ' / From:' + sipJson.headers['From'] + ' / Call-ID: ' + sipJson.headers['Call-ID'],
                    trace: 'trace',
                });
                this.sipConfig.error = errors
                if (code == '11102') {
                    canFuji({ msg: '当前ecp号码处于停机状态', ecpNumber: this.sipPhone }, this)
                }
                // 需要触发自动回拨下一个号码的状态
                if (rcallEndFn && (['480', '487', '603'].includes(status) || (CSeq && CSeq.indexOf('BYE') > -1) || code)) {
                    console.log('需要触发自动回拨下一个号码的状态')
                    if (this.autoDial) {
                        let number = store.state.user.companySet.crm.crm_general_settings
                        .auto_play_time?Number(store.state.user.companySet.crm.crm_general_settings
                            .auto_play_time):6
                        let inter = setInterval(() => {
                            this.autoDialTip = `将在${number}s之后拨打下一个号码`
                            if (this.autoDial == false) {
                                clearInterval(inter)
                                this.autoDialTip = ''
                            }
                            if (number == 0) {
                                clearInterval(inter)
                                this.autoDialTip = ''
                                rcallEndFn()
                            }
                            number -= 1
                        }, 1000);
                    }else{
                        rcallEndFn()
                    }
                }
            });

            // res.ondata = (r) => {
            //     console.log(r.split(' '), 'aaaa')
            // }
            // this.newRTCSession.on("newRTCSession", e => {
            //     console.log(e)
            //     let { session, request, originator } = e, errors = ''
            //     console.log(session._status)
            //     if (session && session._status > -1) {
            //         errors = this.sipCode[session._status]
            //         console.log(errors, request.headers)
            //         if (request.headers && request.headers['X-Reason']) {
            //             let code = request.headers['X-Reason'][0].raw.replace('subcode=', '')
            //             this.sipConfig.error = errors + ':' + this.sipError[code]
            //         } else {
            //             this.sipConfig.error = errors
            //         }
            //     }
            // })
        }
    },
    mounted() {
        // this.init();
    }
}