/*
 * @Author: your name
 * @Date: 2021-06-16 15:04:07
 * @LastEditTime: 2021-07-02 22:03:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /call_manage_system_vue/src/api/business/bill.js
 */
import request from "@/utils/request";

// 新增通话线路类型
export function addLineType(data) {
  return request({
    url: "/business/lineType",
    method: "post",
    data: data,
  });
}

//修改通话线路类型
export function updateLineType(data) {
  return request({
    url: "/business/lineType",
    method: "put",
    data: data,
  });
}

// 获取通话线路类型 详细信息
export function getLineType(id) {
  return request({
    url: "/business/lineType/" + id,
    method: "get",
  });
}

// 删除通话线路类型
export function delLineType(id) {
  return request({
    url: "/business/lineType/" + id,
    method: "delete",
  });
}

// 查询通话线路类型 列表
export function exportLineType(query) {
  return request({
    url: "/business/lineType/list",
    method: "get",
    params: query,
  });
}
