var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.shows,
            title: _vm.title,
            width: "50%",
            center: "",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.shows = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handlerCancer },
                        },
                        [_vm._v("我已阅读并同意")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-scrollbar", { attrs: { height: "500px" } }, [
            _vm.type === 1
              ? _c("div", { staticClass: "agreememt-dia" }, [
                  _c("div", { staticClass: "update-time" }, [
                    _vm._v(" 2023年6月28日更新 "),
                  ]),
                  _c("div", { staticClass: "ag-content hint-title" }, [
                    _vm._v(" 欢迎您使用“" + _vm._s(_vm.$webTitle) + "”！ "),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " “" +
                        _vm._s(_vm.$webTitle) +
                        "”是由浙江网看创享科技有限公司（下称“" +
                        _vm._s(_vm.$webTitle) +
                        "”）自主研发的软件。 本最终用户服务协议（以下称“本协议”）约定您作为最终用户使用“" +
                        _vm._s(_vm.$webTitle) +
                        "”应遵循的基本规范。在接受本协议之前，请您仔细阅读全部内容。您(1)单击或勾选表示接受本协议的对话框，(2)执行引用本协议的订单，或(3)使用免费服务，表示您已阅读并充分理解、接受本协议之内容。如果您代表某个机构而非您个人注册、登录和使用" +
                        _vm._s(_vm.$webTitle) +
                        "软件，则您将被认为获得充分授权代表该机构同意本协议，并接受本协议不时的修改和补充。如果您没有获得上述授权，或您不同意这些条款和条件，您不得接受本协议，不得使用" +
                        _vm._s(_vm.$webTitle) +
                        "提供的服务。特别提示您关注本协议关于限制、免除" +
                        _vm._s(_vm.$webTitle) +
                        "责任的条款、对您的权利限制条款、违规/违约行为的认定处理、以及管辖法院的选择等条款内容，前述条款可能以加粗及/或加下划线形式提示您注意。 您不得为监测服务的可用性、性能或功能，或为任何其他竞争目的访问" +
                        _vm._s(_vm.$webTitle) +
                        "。除非得到" +
                        _vm._s(_vm.$webTitle) +
                        "的事先书面同意，" +
                        _vm._s(_vm.$webTitle) +
                        "的直接竞争对手被禁止访问" +
                        _vm._s(_vm.$webTitle) +
                        "。 由于互联网行业高速发展，本协议并不能完整罗列并覆盖您与" +
                        _vm._s(_vm.$webTitle) +
                        "的所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，您同意" +
                        _vm._s(_vm.$webTitle) +
                        "有权对本协议做修改和补充，并予以公示。通常情况下（例如当需要进行修改或补充以满足适用法律要求时），对本协议的修改将在公布时或规定的生效日立即生效。您继续访问和使用" +
                        _vm._s(_vm.$webTitle) +
                        "即视为您接受修订后的条款。 "
                    ),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 1.服务内容 本条款中的“服务”指：" +
                        _vm._s(_vm.$webTitle) +
                        "向您提供的“" +
                        _vm._s(_vm.$webTitle) +
                        "”SaaS服务，具体功能和服务内容以您所使用或订购的内容为准。 "
                    ),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 2.使用 您或您代表的机构完全拥有在使用“" +
                        _vm._s(_vm.$webTitle) +
                        "”时录入的数据和资料（包括但不限于客户资料、联系人、文档、沟通信息）的所有权，并对数据和资料的准确性与合法性负责，不得违反个人数据保护的相关规定。如您决定在免费试用期或付费的授权许可日期结束后不再使用" +
                        _vm._s(_vm.$webTitle) +
                        "CRM，您需在许可截止日期后3日内将储存在" +
                        _vm._s(_vm.$webTitle) +
                        "CRM内的数据和资料导出备份，" +
                        _vm._s(_vm.$webTitle) +
                        "将不再系统中继续保存您的数据，如果您决定继续使用" +
                        _vm._s(_vm.$webTitle) +
                        "CRM，您需及时与" +
                        _vm._s(_vm.$webTitle) +
                        "或代理商签署购买协议。 "
                    ),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 3.知识产权 " +
                        _vm._s(_vm.$webTitle) +
                        "完全拥有" +
                        _vm._s(_vm.$webTitle) +
                        "服务及服务所包含的软件（包括对其进行的任何开发和升级）的所有权及所有相关的知识产权（包括但不限于源代码、目标代码、文档资料、界面设计、版面框架、产品logo、以及任何由" +
                        _vm._s(_vm.$webTitle) +
                        "根据维护与技术支持提供的软件、资料等）。您认同" +
                        _vm._s(_vm.$webTitle) +
                        "拥有的所有权及知识产权，并承诺尊重及不以任何方式侵害" +
                        _vm._s(_vm.$webTitle) +
                        "的所有权及知识产权。 "
                    ),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 4.未经" +
                        _vm._s(_vm.$webTitle) +
                        "书面许可，您不得从事以下行为，否则应承担相应的法律责任:(a)将" +
                        _vm._s(_vm.$webTitle) +
                        "软件用于非本协议用途的其他目的，包括但不限于转售、转租、转让、提供分许可；(b) 模仿" +
                        _vm._s(_vm.$webTitle) +
                        "的产品和服务，对" +
                        _vm._s(_vm.$webTitle) +
                        "CRM进行改变、反编译、反汇编等反向工程处理;(c)复制和模仿" +
                        _vm._s(_vm.$webTitle) +
                        "的任何设计、界面、功能和图表,并生成与" +
                        _vm._s(_vm.$webTitle) +
                        "CRM相类似或竞争的产品或服务；(d) 对" +
                        _vm._s(_vm.$webTitle) +
                        "CRM或服务进行修改或制造、创建衍生产品或派生其他产品；(e)其他侵犯乙方知识产权的行为。 "
                    ),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 5.数据安全 " +
                        _vm._s(_vm.$webTitle) +
                        "将尽合理的努力保护您的信息安全，并为此采取合理的数据传输、存储、转换等预防保护措施。但是，互联网数据传输、存储、转换均可能存在一定未知且不确定的数据安全风险，该等风险将导致包括但不限于数据丢失、泄露、损坏、无法读取或提取等后果。您确认，您已明确知晓并同意接受该等因互联网引发的风险和后果，并已采取恰当的措施（例如数据备份等），以便在该等风险发生时将损失降至最低。因互联网技术本身等非" +
                        _vm._s(_vm.$webTitle) +
                        "主观故意或重大过失导致危害您数据安全的，" +
                        _vm._s(_vm.$webTitle) +
                        "不承担赔偿责任。因" +
                        _vm._s(_vm.$webTitle) +
                        "的重大过失危害您数据安全的，" +
                        _vm._s(_vm.$webTitle) +
                        "的赔偿责任以您支付的年度服务费用为上限。 "
                    ),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 6.隐私政策 我们将严格按照法律法规的要求保护您的隐私数据，具体内容详见《" +
                        _vm._s(_vm.$webTitle) +
                        "隐私声明》 "
                    ),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(" 7.保密"),
                    _c("br"),
                    _vm._v(
                      " 7.1 “机密信息”是指一方(“披露方”)向另一方(“接收方”)披露的、经口头或书面指定为机密的或考虑到信息的性质和披露情况应合理理解为机密的所有信息，您和" +
                        _vm._s(_vm.$webTitle) +
                        "互为披露方和接收方。机密信息的提供方式包括但不限于口头、书面、影像、磁盘、光盘、 胶片、数据电文。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 7.2 保密信息的具体内容包括：(a)您方保存在乙方服务器上的数据，包括经营状况、技术运营状况、财务状况、市场信息、客户信息、内部管理信息、人力资源信息等； (b)与本协议内容有关的所有信息，包括但不限于服务内容、许可费率等；(c) 在协议有效期内，甲方知悉的乙方的代码、文档、数据、模型、用例、 草案、技术、方法、设备信息、软件工具信息等； (d)其他甲乙双方各自专有的、且提供给对方的非公开信息。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 7.3 以下信息不属于保密信息： (a) 非由于接收方的原因已经为公众所知的信息； (b) 披露方事先书面同意接收方可以公开的信息； (c)在披露方向接收方披露保密信息以前，接收方已获知的保密信息，但前提是接收方不知晓该信息的来源受任何协议或规定的约束。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 7.4 接收方仅能为实现本协议之目的接触和使用透露方的数据和资料，除非基于法律规定或者有权机关的要求，在任何情况下接收方不得向任何第三方泄露透露方的保密信息。 本保密条款在本协议终止后依然长期有效，直至相关保密信息成为公开信息或没有保密的必要。 "
                    ),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 8.免责声明 您知悉并同意，下列情况下，" +
                        _vm._s(_vm.$webTitle) +
                        "无需向您承担任何责任： 由于您维护不当或保密不当，导致数据、资料、口令、密码等丢失或泄漏，或其他非因" +
                        _vm._s(_vm.$webTitle) +
                        "原因导致的个人信息的泄漏； 任何由于不可抗力、黑客攻击、电脑病毒侵入、网络运营商原因、第三方服务商原因或政府管制等" +
                        _vm._s(_vm.$webTitle) +
                        "无法合理控制的原因而造成" +
                        _vm._s(_vm.$webTitle) +
                        "CRM使用中止、中断、延误、使用限制、使用异常、使用不能、暂时性关闭等； 您理解并确认，受技术所限，" +
                        _vm._s(_vm.$webTitle) +
                        "无法保证其所提供的服务毫无瑕疵，但我们承诺将不断提升服务质量及服务水平，为您提供更加优质的服务。据此，您同意：如果" +
                        _vm._s(_vm.$webTitle) +
                        "所提供的服务虽然存在瑕疵，但该等瑕疵是当时行业技术水平所无法避免的，其将不被视为违约，" +
                        _vm._s(_vm.$webTitle) +
                        "无须因此向您承担任何责任。 "
                    ),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 9.服务变更/中断/终止 如因系统维护或升级的需要而暂停网络服务、调整服务功能的，" +
                        _vm._s(_vm.$webTitle) +
                        "将尽可能事先在网站或" +
                        _vm._s(_vm.$webTitle) +
                        "系统上进行通告。 "
                    ),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 10.若您利用本" +
                        _vm._s(_vm.$webTitle) +
                        "软件从事任何违法或侵权行为，由您或您代表的机构自行承担全部责任，" +
                        _vm._s(_vm.$webTitle) +
                        "不承担任何法律责任。 "
                    ),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 11.其他 本协议及其下的服务受中华人民共和国大陆地区（不含港、澳、台地区）法律管辖，并按之解释。因本协议引起的或与本协议有关的任何争议，双方同意友好协商，协商不成的，任何一方可将争议提交" +
                        _vm._s(_vm.$webTitle) +
                        "所在地有管辖权的人民法院通过诉讼方式解决。 "
                    ),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 12.协议的完善和修改 " +
                        _vm._s(_vm.$webTitle) +
                        "有权根据互联网的发展和中华人民共和国有关法律、法规的变化，不时地完善和修改本协议。" +
                        _vm._s(_vm.$webTitle) +
                        "保留随时修改本协议的权利，当发生相关争议时，以最新的协议约定为准。如果本协议与您与" +
                        _vm._s(_vm.$webTitle) +
                        "另行签署的书面协议有不一致之处，以双方另行签署的书面协议为准。 "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.type === 2
              ? _c("div", { staticClass: "agreememt-dia" }, [
                  _c("div", { staticClass: "update-time" }, [
                    _vm._v(" 2023年6月28日更新 "),
                  ]),
                  _c("div", { staticClass: "ag-content hint-title" }, [
                    _vm._v(" 提示条款 "),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$webTitle) +
                        "（下称“我们”）非常注重保护用户（下称“您”）的个人信息及隐私，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。有鉴于此，我们制定本《" +
                        _vm._s(_vm.$webTitle) +
                        "个人信息保护政策》（下称“本政策”）并希望通过本政策向您清晰地介绍在使用我们的产品/服务时， 我们将如何收集、使用、存储、加工、传输、提供、公开您的个人信息，以及我们为您提供的查询、更正、删除和保护这些信息的方式。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " 本政策适用于" +
                        _vm._s(_vm.$webTitle) +
                        "软件产品及增值服务。在使用" +
                        _vm._s(_vm.$webTitle) +
                        "软件产品或服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体标识的条款，您应重点阅读。一旦您勾选“同意”本政策，即表示您已同意我们按照本政策处理您的个人信息。如对本政策有任何疑问，您可以通过本政策“十、如何联系我们”中提供的方式与我们联系。 "
                    ),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v("本政策将帮助您了解以下内容："),
                  ]),
                  _c("h3", [_vm._v("一、定义")]),
                  _c("h3", [_vm._v("二、我们如何收集和使用您的个人信息")]),
                  _c("h3", [_vm._v("三、我们如何使用Cookie和同类技术")]),
                  _c("h3", [
                    _vm._v("四、我们如何提供、转移、公开您的个人信息"),
                  ]),
                  _c("h3", [_vm._v("五、我们如何保护和保存您的个人信息")]),
                  _c("h3", [
                    _vm._v("六、第三方产品或服务如何获得您的个人信息"),
                  ]),
                  _c("h3", [_vm._v("七、您如何管理您的个人信息")]),
                  _c("h3", [_vm._v("八、我们如何处理未成年人的个人信息")]),
                  _c("h3", [_vm._v("九、本政策如何更新")]),
                  _c("h3", [_vm._v("十、如何联系我们")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 针对某些特定的产品/服务，我们可能还将制定单独的个人信息保护政策，向您说明这些产品/服务的特殊政策。如相关特定的个人信息保护政策与本政策有不一致之处，适用该特定个人信息保护政策。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 请您注意，本政策不适用于您通过我们的产品/服务而接入的其他第三方产品/服务（“其他第三方”，包括您的任何第三方网站以及第三方服务提供者等），具体规定请参照该第三方的个人信息保护政策、隐私政策或类似声明。 "
                    ),
                  ]),
                  _c("h3", [_vm._v("一、定义")]),
                  _c("h5", [_vm._v("1." + _vm._s(_vm.$webTitle) + ":")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 指研发并运营" +
                        _vm._s(_vm.$webTitle) +
                        "产品和服务的浙江网看创享科技有限公司及相关关联公司的单称或合称。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("2." + _vm._s(_vm.$webTitle) + "CRM:")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 指由" +
                        _vm._s(_vm.$webTitle) +
                        "开发和运营的为企业提供拓客、触客、管客到成交的一体化智能解决方案。" +
                        _vm._s(_vm.$webTitle) +
                        "CRM的展现形式包括" +
                        _vm._s(_vm.$webTitle) +
                        "CRM网站(域名为：www.iqiyu.net)、H5页面、客户端（包括Windows、Mac、iOS、Android等多个应用版本）、将" +
                        _vm._s(_vm.$webTitle) +
                        "CRM的部分功能集成在我们的关联公司和/或其他第三方智能硬件或软件中提供的服务。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("3.关联公司:")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 指" +
                        _vm._s(_vm.$webTitle) +
                        "现在或将来控制、受控制或与其出于共同控制下的任何公司、合法机构以及上述公司、合法机构的合法继承人。控制通过下列方式存在，包括但不限于：直接或间接的拥有超过50％的股份，或是直接或间接拥有股份虽未超过50%，但能对股东会决议施加重大影响或能够实际支配公司的行为。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("4.个人信息:")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 指以电子或者其他方式记录的已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("5.敏感个人信息:")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("6.个人信息删除:")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("7.匿名化处理:")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。个人信息经匿名化处理后所得的信息不属于个人信息。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("8.去标识化处理:")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 是指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。 "
                    ),
                  ]),
                  _c("h3", [_vm._v("二、我们如何收集和使用您的个人信息")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 在您使用我们的产品及/或服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种: "
                    ),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 1、为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务； "
                    ),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 2、为实现向您提供我们产品及/或服务的扩展功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关扩展功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及/或服务的基本功能。 "
                    ),
                  ]),
                  _c("h4", [
                    _vm._v("（一）基本功能中我们收集和使用您个人信息的情形"),
                  ]),
                  _c("h5", [_vm._v("1、完成注册")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 当您注册" +
                        _vm._s(_vm.$webTitle) +
                        "CRM时，为注册账号使用我们的服务，您需要提供手机号码、所在企业信息（包括企业名称、企业地区）、用户姓名用于创建账户。我们收集上述信息是为了帮助您完成" +
                        _vm._s(_vm.$webTitle) +
                        "CRM账号的注册，如您不提供上述信息，我们将无法为您创建账户并正常向您提供服务。为了向您提供服务，我们会收集您的设备信息（包括设备名称、设备型号、MAC地址、设备识别码、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN））、设备所在位置相关信息（包括您授权的GPS位置信息以及WLAN接入点、蓝牙和基站传感器信息）、浏览器类型来对相应的商品或者服务内容进行排序以适应您的设备界面。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("2、消息推送")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 当您使用" +
                        _vm._s(_vm.$webTitle) +
                        "CRM时，我们会通过华为/小米/个推SDK收集您的设备识别码。我们收集上述信息是为了帮助您即时收到" +
                        _vm._s(_vm.$webTitle) +
                        "CRM推送的消息。如您不提供上述信息，则我们将无法正常向您提供服务。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("3、个性化内容")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 当您使用" +
                        _vm._s(_vm.$webTitle) +
                        "CRM时，我们会通过SDK收集您的设备识别码。我们收集上述信息是为了帮助您接收个性化推广，以便将您感兴趣的产品或服务信息（在首页资讯和推荐企业栏）优先展示给您。如您不提供上述信息，则我们将无法正常向您提供服务。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("4、销售管理")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 在您使用" +
                        _vm._s(_vm.$webTitle) +
                        "CRM时，我们将收集您的网络行为轨迹（包括获得线索数、获得商机数、联系人数、跟进次数、签到次数、打电话次数、赢单数量、赢单金额、待跟进线索、待跟进客户、三天内预计成交商机、即将掉保的线索、即将掉保的客户）。我们收集您的上述信息是为了帮助你了解您对" +
                        _vm._s(_vm.$webTitle) +
                        "CRM的使用情况，并呈现在“应用-数据中心”的“业务统计”、“销售漏斗”、“目标完成度”、“跟进统计”以及“客户”的“仪表盘”和“待办客资”中，以便于您进行销售管理。如您不提供上述信息，则我们将无法正常向您提供服务。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("5、保障服务的安全性和可靠性")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 为了保障服务的安全性和可靠性，我们会通过腾讯Bugly SDK需要收集您的设备型号、操作系统版本、唯一设备识别符、IP地址、浏览器类型、使用的语言、访问日期和时间、接入网络的方式、网络质量数据、服务日志信息。我们收集您的上述信息是为了保障应用运行安全，如您不提供上述信息，则我们无法保证您在使用期间的应用运行安全。 "
                    ),
                  ]),
                  _c("h4", [
                    _vm._v("（二）扩展功能中我们收集和使用您个人信息的情形"),
                  ]),
                  _c("h5", [_vm._v("1、一键登录")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 当您使用手机号码一键登录" +
                        _vm._s(_vm.$webTitle) +
                        "CRM时，我们会通过SDK收集您的手机号码运营商、手机号码、IP地址、手机机型、系统类型、系统版本、网络环境、网关取号报错日志、IMEI（首次安装登录时获取一次）、IMSI、ICCID等信息。我们收集上述信息是为了帮助您免于使用密码或手机短信验证码进行登录，以便于 您快捷地完成登录，并保证您的账号安全性。如您不提供上述信息，您可以选择使用微信授权登录，但不影响您正常使用我们的其他服务。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("2、补充名片信息")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 在您使用名片功能时，您可以上传头像图片、填写邮箱、微信号、QQ号。我们收集您的上述信息是为了丰富您在名片中展示的信息。如您不提供上述信息，则我们将无法在名片中展示您多种联系方式，但不影响您正常使用我们的其他服务。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("3、查询附近企业")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 当您使用寻客宝的“附近企业”功能时，我们会通过高德地图SDK收集您地理位置信息。我们收集上述信息是为了定位您的地理位置，以便查询您附近的企业。如您不授权或关闭系统授权开启定位功能，我们将无法为您提供查询附件企业的服务，但不影响您正常使用我们的其他服务。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("4、通话频次与质量监控")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 当您使用" +
                        _vm._s(_vm.$webTitle) +
                        " CRM的呼叫中心时，我们会通过" +
                        _vm._s(_vm.$webTitle) +
                        "APP及客户端收集您的呼叫总数、呼叫总时长、呼叫成功率、呼叫的事件、来电总数、来电总数长、来电接通率、通话录音、呼出／呼入时间、呼出／呼入时长、呼出／呼入总数、通话录音、主叫／被叫号码、号码归属地、呼入／呼出结果。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("5、短信群发")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 当您使用" +
                        _vm._s(_vm.$webTitle) +
                        "CRM短信群发功能时，我们会通过创蓝SDK收集您的营销内容信息、您的线索池中的或用户导入的联系方式、短信签名、短信内容、客户姓名、链接网址。创蓝SDK收集上述信息是为了帮助您传输您向其他用户发送的内容，以便于您使用短信群发功能。如您不提供上述信息，您将无法进行短信群发，但不影响您正常使用我们的其他服务。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("6、添加员工")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 当您使用" +
                        _vm._s(_vm.$webTitle) +
                        "CRM添加成员功能时，我们会请求您授权获取您通讯录的联系人以导入组织架构。我们收集上述信息是为了帮助您在组织架构里完成成员添加，以便于您更好地管理您的成员。如您不授权或在系统权限中关闭授权，你可以选择使用手动输入添加或邀请成员加入，但不影响您正常使用我们的其他服务。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("7、加入企业")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 当您使用" +
                        _vm._s(_vm.$webTitle) +
                        "CRM的加入企业功能时，我们会请求授权获取您的设备相机权限和摄像机信息，收集上述信息是为了帮助您通过面对面扫描同事的企业邀请二维码的方式加入企业。如您不授权或在系统权限中关闭授权，你还可以选择通过手动输入企业号的方式加入企业，但不影响您正常使用我们的其他服务。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("8、客服及争议处理")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 当您与我们联系或提出争议纠纷处理申请时，为了保障您的账户及系统安全，我们需要您提供手机号码、身份证信息、营业执照以核验您的身份。如您不提供上 述信息，您将无法有效地联系客服或进行争议纠纷处理，但不影响您正常使用我们的服务。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " 为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会收集您与我们的通信/通话记录、您的账号信息、订单信息、您为了证明相关事实提供的信息、您留下的联系方式信息。如您不提供上述信息，您向我们提出的问题可能无法得到及时、有效处理，但不影响您正常使用我们的服务。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("9、问卷调查")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 为了改进服务质量的合理需要，我们在您参与问卷调查时，收集您向我们发送的问卷答复信息。我们收集上述信息是为了统计问卷反馈并优化服务，如您不提供这类信息，我们将无法通过问卷了解您对我们服务提出的建议和反馈，但不影响您正常使用我们的其他服务。 "
                    ),
                  ]),
                  _c("h4", [_vm._v("（三）其他")]),
                  _c("h5", [_vm._v("1、您上传他人的个人信息")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 若您上传的信息中含有其他最终用户的个人信息，在您向" +
                        _vm._s(_vm.$webTitle) +
                        "上传这些个人信息之前，您需确保您已经取得合法的授权且已充分告知其他最终用户相关个人信息收集的目的、范围、使用方式、保护措施和委托我们进行处理的情况等。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("2、超出授权同意范围使用个人信息")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 若我们将个人信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的个人信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("3、我们间接收集的个人信息")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 若我们从第三方处间接获取您的个人信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集个人信息，并向您告知获取的信息内容，且涉及敏感个人信息的在提供给我们使用前需经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺。我们会使用不低于我们对直接收集个人信息同等的保护手段与措施对间接获取的个人信息进行保护。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("4、征得授权同意的例外")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意:"
                    ),
                    _c("br"),
                    _vm._v(" (1)为订立、履行个人作为一方当事人的合同所必需；"),
                    _c("br"),
                    _vm._v(" (2)为履行法定职责或者法定义务所必需；"),
                    _c("br"),
                    _vm._v(
                      " (3)为应对突发公共卫生事件，或者紧急情况下为保护你的生命、健康和财产安全所必需；"
                    ),
                    _c("br"),
                    _vm._v(
                      " (4)为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理你的个人信息；"
                    ),
                    _c("br"),
                    _vm._v(
                      " (5)在合理的范围内处理你自行公开或者其他已经合法公开的个人信息；"
                    ),
                    _c("br"),
                    _vm._v(" (6)法律、行政法规规定的其他情形。"),
                    _c("br"),
                  ]),
                  _c("h3", [_vm._v("三、我们如何使用Cookie和同类技术")]),
                  _c("h4", [_vm._v("（一）Cookie")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储Cookie、Flash Cookie或浏览器(或关联应用程序)提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储(统称“Cookie”)。借助于Cookie，网站能够存储您的偏好等数据。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " 如果您的浏览器或浏览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。但如果您这么做，在某些情况下可能会影响您安全访问我们的网站，且可能需要在每一次访问我们的网站时更改用户设置。 "
                    ),
                  ]),
                  _c("h4", [_vm._v("（二）Cookie同类技术")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " ETag（实体标签）是在互联网浏览器与互联网服务器之间背后传送的HTTP协议标头，可代替Cookie。ETag可以帮助我们避免不必要的服务器负载，提高服务效率，节省能源。同时，我们可能通过ETag记录您的身份，以便我们可以更深入地了解和改善我们的产品或服务。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " 大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作。但如果停止使用ETag，您可能无法享受相对更佳的产品或服务体验。 "
                    ),
                  ]),
                  _c("h3", [
                    _vm._v("四、我们如何提供、转移、公开您的个人信息"),
                  ]),
                  _c("h4", [_vm._v("（一）提供")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 我们不会与" +
                        _vm._s(_vm.$webTitle) +
                        "以外的公司、组织和个人共享您的个人信息，但以下情况除外: "
                    ),
                  ]),
                  _c("h5", [_vm._v("1、在获取明确同意的情况下的提供")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 获得您的明确同意后，我们可能会向其他第三方提供您的个人信息。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("2、向关联公司提供")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 基于" +
                        _vm._s(_vm.$webTitle) +
                        "CRM账户向您提供产品和服务或实现外部处理的目的，我们可能会向关联公司提供您的个人信息，让他们按照我们的个人信息保护政策和其他相关的保密和安全措施来为我们处理上述信息，并用于向您提供我们的服务，实现“我们如何收集和使用您的个人信息”部分所述目的。我们会采取严格的技术手段保障你的信息安全。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("3、委托处理")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的委托其处理您的个人信息，授权合作伙伴只能接触到其履行职责所需个人信息，且不得将此信息用于其他任何目的。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " （1）广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息提供给提供广告、分析服务的合作伙伴。我们会委托这些合作伙伴处理与广告覆盖面和有效性相关的信息，但不会提供您的个人身份信息，或者我们将这些信息进行去标识化处理，以便它不会识别您个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以执行我们委托的广告服务或决策建议。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " （2）供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。 "
                    ),
                  ]),
                  _c("h4", [_vm._v("（二）转移")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 我们不会将您的个人信息转移给任何公司、组织和个人，但以下情况除外："
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " 1、在获取明确同意的情况下转移：获得您的明确同意后，我们会向其他方转移您的个人信息。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 2、合并、收购或破产清算：在" +
                        _vm._s(_vm.$webTitle) +
                        "发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转移，我们将告知您相关情形，按照法律法规及不低于本政策所要求的标准要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。 "
                    ),
                  ]),
                  _c("h4", [_vm._v("（三）公开")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(" 我们仅会在以下情况下，公开披露您的个人信息: "),
                    _c("br"),
                    _vm._v(
                      " 1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息； "
                    ),
                    _c("br"),
                    _vm._v(
                      " 2、如果我们确定您出现违反法律法规或严重违反" +
                        _vm._s(_vm.$webTitle) +
                        "相关协议及规则的情况，或为保护" +
                        _vm._s(_vm.$webTitle) +
                        "用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及" +
                        _vm._s(_vm.$webTitle) +
                        "平台已对您采取的措施。 "
                    ),
                  ]),
                  _c("h4", [
                    _vm._v(
                      "（四）提供、转移、公开个人信息时事先征得授权同意的例外"
                    ),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 以下情形中，提供、转移、公开您的个人信息无需事先征得您的授权同意:"
                    ),
                    _c("br"),
                    _vm._v(" 1、与国家安全、国防安全有关的；"),
                    _c("br"),
                    _vm._v(" 2、与公共安全、公共卫生、重大公共利益有关的；"),
                    _c("br"),
                    _vm._v(
                      " 3、与刑事侦查、起诉、审判和判决执行等司法或行政执法有关的；"
                    ),
                    _c("br"),
                    _vm._v(
                      " 4、出于维护您或其他个人的生命、健康、财产等重大合法权益但又很难得到本人同意的；"
                    ),
                    _c("br"),
                    _vm._v(" 5、您自行向社会公众公开的个人信息；"),
                    _c("br"),
                    _vm._v(
                      " 6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " 请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的提供、转移、公开无需另行向您通知并征得您的同意。 "
                    ),
                  ]),
                  _c("h3", [_vm._v("五、我们如何保护和保存您的个人信息")]),
                  _c("h4", [_vm._v("（一）我们如何保护您的个人信息")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 1、我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL协议加密保护；我们同时对" +
                        _vm._s(_vm.$webTitle) +
                        "网站提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击;我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 2、我们有行业先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 3、在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知:安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。 "
                    ),
                  ]),
                  _c("h4", [_vm._v("（二）我们如何保存您的个人信息")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 1、我们将在本政策载明的目的所需的最短保存期限之内，保存您的个人信息，除非法律法规另有规定，或您向我们另行授权延长保存期限。前述期限届满后，我们将对您的个人信息做删除或匿名化处理。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 2、我们将您的个人信息保存在中国境内。如需将您的个人信息传输至中国境外时，我们会另行征求您的同意，并遵守相关法律规定。 "
                    ),
                  ]),
                  _c("h3", [
                    _vm._v("六、第三方产品或服务如何获得您的个人信息"),
                  ]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 您同意并理解，为了更好地提供服务，我们的产品可能会集成或接入第三方服务，如SDK、API或H5等。这些第三方服务商独立运营，单独获得您的授权同意，直接收集您的个人信息，并独立承担相关的责任和义务。 "
                    ),
                  ]),
                  _c("h3", [_vm._v("七、您如何管理您的个人信息")]),
                  _c("h4", [_vm._v("（一）您对您的个人信息享有以下权利")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 在您使用" +
                        _vm._s(_vm.$webTitle) +
                        "期间，为了您可以更加便捷地查阅、复制、更正、补充、删除您的个人信息及要求我们解释个人信息处理政策，同时保障您撤回同意、注销帐号等权利，我们在产品设计中为您提供了相应的权限管理功能，您可以参考下面的指引进行操作。请注意，不同操作系统、APP的不同版本之间，操作设置可能存在差异。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("1、您有权查阅、复制或更正您的个人信息")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 如果您想行使个人信息查阅、复制或更正权，您可以通过以下方式自行查阅、复制或更正："
                    ),
                    _c("br"),
                    _vm._v(
                      " （1）账户信息：如果您希望查阅、复制或更正您的账户绑定的手机号、个人资料信息、更改您的密码等，您可以通过访问" +
                        _vm._s(_vm.$webTitle) +
                        "CRM执行此类操作。"
                    ),
                    _c("br"),
                    _vm._v(
                      " （2）搜索信息：您可以在" +
                        _vm._s(_vm.$webTitle) +
                        "CRM中查阅、复制或清除您的搜索历史记录、查阅和修改兴趣以及管理其他数据 "
                    ),
                  ]),
                  _c("h5", [_vm._v("2、您有权删除您的个人信息")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(" 在以下情形中，您有权向我们提出删除您的个人信息："),
                    _c("br"),
                    _vm._v(
                      " （1）我们违反法律法规或与您的约定收集、使用个人信息；"
                    ),
                    _c("br"),
                    _vm._v(
                      " （2）我们违反法律法规或与您的约定向第三方提供或转移您的个人信息，我们将立即停止提供、转移行为，并通知第三方及时删除；"
                    ),
                    _c("br"),
                    _vm._v(
                      " （3）我们违反法律法规规定或与您的约定，公开您的个人信息，我们将立即停止公开的行为，并发布通知要求相关接收方删除相应的信息；"
                    ),
                    _c("br"),
                    _vm._v(
                      " （4）您不再使用我们的产品或服务，或您注销了账号，或我们终止服务及运营。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("3、您有权改变您授权同意的范围")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 您可以通过本政策载明的方式与我们联系，以改变同意范围或撤回您的授权。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("4、您有权注销您的账号")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 您可以通过访问我们APP页面上“我的”---->“设置”---->“账号管理”---->“注销账号”中进行在线操作。有关注销账户的相关规则和流程，请参见注销账号页面中的在线《注销协议》。您注销成功后，我们将根据法律法规的要求尽快删除您 的个人信息或作匿名化处理。鉴于我们使用了统一的账号系统，您理解并同意如您选择在" +
                        _vm._s(_vm.$webTitle) +
                        "中实施账户注销，将可能会影响您使用" +
                        _vm._s(_vm.$webTitle) +
                        "其他产品。 "
                    ),
                  ]),
                  _c("h5", [_vm._v("5、其他权利")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 根据《个人信息保护法》等相关规定，在符合法定条件下，您及您的近亲属还享有下列个人信息权利："
                    ),
                    _c("br"),
                    _vm._v(" （1）补充个人信息；"),
                    _c("br"),
                    _vm._v(" （2）要求我们对个人信息处理规则进行解释说明；"),
                    _c("br"),
                    _vm._v(
                      " （3）根据《个人信息保护法》的规定，若自然人死亡的，其近亲属为了自身合法、正当利益可以对死者的相关个人信息行使查询、复制、更正、删除权利。若您的近亲属希望查询、复制、更正、删除你的个人信息，可以通过“十、如何联系我们”中的联系方式与我们联系，在核实您的身份后，为进一步需要核实的事实及情形的需要，我们有权要求您的近亲属进一步提供证明或说明材料，我们会在核实后向您的近亲属提供相应服务。但法律法规及本政策另有约定的除外。 "
                    ),
                  ]),
                  _c("h4", [_vm._v("（二）响应您的上述请求")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 如您无法按照上述方式查阅、复制、更正、补充、删除您的个人信息以及注销账号，您可以通过本政策“十、如何联系我们”载明的联系方式与我们联系。为保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份和请求的正当性，我们将在收到您反馈并验证您的身份后的15个工作日内答复您的请求。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " 对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。 "
                    ),
                  ]),
                  _c("h4", [_vm._v("（三）响应情形的例外")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(" 在以下情形中，我们将无法响应您的请求:"),
                    _c("br"),
                    _vm._v(" 1、与我们履行法律法规规定的义务相关的;"),
                    _c("br"),
                    _vm._v(" 2、与国家安全、国防安全直接相关的;"),
                    _c("br"),
                    _vm._v(" 3、与公共安全、公共卫生、重大公共利益直接相关的;"),
                    _c("br"),
                    _vm._v(" 4、与刑事侦查、起诉、审判和执行判决等直接相关的;"),
                    _c("br"),
                    _vm._v(
                      " 5、我们有充分证据表明个人信息主体存在主观恶意或滥用权利的;"
                    ),
                    _c("br"),
                    _vm._v(
                      " 6、出于维护您或其他个人的生命、健康、财产等重大合法权益但又很难得到本人同意的;"
                    ),
                    _c("br"),
                    _vm._v(
                      " 7、响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的;"
                    ),
                    _c("br"),
                    _vm._v(" 8、涉及商业秘密的 "),
                  ]),
                  _c("h4", [_vm._v("八、我们如何处理未成年人的个人信息")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$webTitle) +
                        "CRM为您提供的产品或服务为销售工具，我们不向未成年人提供服务。如果您为未成年人，请您不要向我们提供个人信息。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 如果有事实证明我们收集了未成年人的个人信息，我们在知晓后会设法尽快删除相关信息。 "
                    ),
                  ]),
                  _c("h4", [_vm._v("九、本政策如何更新")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " （一）我们的政策可能变更。未经您明确同意，我们不会限制您按照本政策所应享有的权利。我们会在" +
                        _vm._s(_vm.$webTitle) +
                        "APP端“我的”---->“设置”---->“关于" +
                        _vm._s(_vm.$webTitle) +
                        "”---->“" +
                        _vm._s(_vm.$webTitle) +
                        "隐私权政策”中发布对本政策所做的任何变更。我们会在网页端通过包括但不限于站内信、网页弹窗、权限管理页面或网站末尾发布对本政策所做的任何变更。"
                    ),
                    _c("br"),
                    _vm._v(
                      " （二）对于重大变更，我们还会提供更为显著的通知（包括我们向您提供弹窗提示提醒您阅读新的隐私权政策）。本政策所指的重大变更:"
                    ),
                    _c("br"),
                    _vm._v(
                      " 1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；"
                    ),
                    _c("br"),
                    _vm._v(
                      " 2、我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；"
                    ),
                    _c("br"),
                    _vm._v(" 3、个人信息提供、转移或公开的主要对象发生变化；"),
                    _c("br"),
                    _vm._v(
                      " 4、您参与个人信息处理方面的权利及其行使方式发生重大变化；"
                    ),
                    _c("br"),
                    _vm._v(
                      " 5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；"
                    ),
                    _c("br"),
                    _vm._v(" 6、个人信息安全影响评估报告表明存在高风险。 "),
                  ]),
                  _c("h4", [_vm._v("十、如何联系我们")]),
                  _c("div", { staticClass: "ag-content" }, [
                    _vm._v(
                      " 如您对本政策内容有任何疑问、意见或建议，或发现个人信息可能被泄露，您可以通过客服部门与我们联系，我们将在15个工作日内回复您的请求。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 您可以将您的书面疑问、意见或建议通过以下地址寄至客服部门："
                    ),
                    _c("br"),
                    _vm._v(" 公司名称：浙江网看创享科技有限公司"),
                    _c("br"),
                    _vm._v(
                      " 如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报，或者通过向杭州市西湖区人民法院提起诉讼来寻求解决方案。 "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }