import Cookies from "js-cookie";

const TokenKey = "Admin-Token";
const GroupKey = "group";
import { domain, domainUrl } from "@/utils/setting";
import store from '@/store'
export function getToken() {
    let token = ''
    try {
        token = localStorage.getItem(TokenKey)
    } catch (error) {

    }
    return token
    // return Cookies.get(TokenKey);
}

export function setToken(token) {
    // console.log('setToken', token)
    localStorage.setItem(TokenKey, token)
    setCookie(TokenKey, token, 1000)
    Cookies.set(TokenKey, token, { expires: 1000, domain });
    return;
}
// 设置cookie
function setCookie(name, value, daysToLive) {
    let cookie = name + "=" + encodeURIComponent(value);
    if (typeof daysToLive === "number") {
        cookie += "; max-age=" + (daysToLive * 24 * 60 * 60); // max-age单位是秒
    }
    document.cookie = cookie;
}

// 获取cookie
function getCookie(name) {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
        const [cookieName, cookieValue] = cookies[i].split("=");
        if (name === cookieName) {
            return decodeURIComponent(cookieValue);
        }
    }
    return "";
}

export function removeToken() {
    localStorage.removeItem(TokenKey)
    clearCookie()
    Cookies.remove(TokenKey, { domain })
    if (window.aegis) {
        window.aegis.infoAll({
            msg: 'pc-token是否清除：' + window.localStorage.getItem(TokenKey),
            trace: 'trace',
        });
    }
    return;
}
function clearCookie() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}
export function getGroup() {
    return Cookies.get(GroupKey);
}

import { geoLiteip } from '@/api/login'
let showTip = false
export async function checkIp(that) {
    if (showTip) {
        return
    }
    let res = await geoLiteip()
    if (res.code == 200 && !['香港', '台湾', '澳门'].includes(res.data.country) && (res.data.province || res.data.country == '中国')) {
        return false
    } else {
        showTip = true
        if (that) {
            that.$alert(`系统检测到你当前登陆ip地址在${res.data.country}${res.data.city ? '-' + res.data.city : ''}， 请确保您的地区在中国大陆，或者关闭代理软件再使用。 否则会引起运营商封号`, '温馨提示', {
                confirmButtonText: '确定',
                callback: action => {
                    showTip = false
                }
            });
        }
        return true
    }
}

// 麦克风授权
export function getRecordQX() {
    // 检查浏览器是否支持navigator.mediaDevices.getUserMedia
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // 请求访问用户的麦克风
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(function (stream) {
                console.log('麦克风已授权，音频流已获取');
            })
            .catch(function (err) {
                // 处理错误，例如用户拒绝授权
                console.error('无法获取麦克风访问权限:', err);
            });
    } else {
        console.error('浏览器不支持navigator.mediaDevices.getUserMedia');
    }
}

// 检测ip如果是测试账号每次拨打都需要检测ip 如果不是，就不需要
export async function FirewallEnforcedIP(that) {
    const permissions = store.getters && store.getters.permissions
    if (permissions.includes('FirewallEnforcedIP')) {
        let bool = await checkIp(that)
        return bool
    } else {
        return false
    }
}