import * as qiniu from 'qiniu-js';

export default function QiniuUploader(options) {
    console.log('QiniuUploader')
    this.config = {
        bucket: options.bucket, // 存储空间名称
        region: options.region? options.region:'z0', // 区域，默认为华东（z0）
        uploadToken: options.token, // 上传凭证
        domain: options.domain || 'http://ssdicnbj7.hd-bkt.clouddn.com', // 测试域名
        chunkSize: options.chunkSize || 4 * 1024 * 1024, // 分片大小，默认4MB
        maxRetry: options.maxRetry || 3, // 最大重试次数
        ...options
    };
    this.file = null; // 当前上传的文件
    this.uploadProgress = {}; // 上传进度
    this.isPaused = false; // 是否暂停
    this.retryCount = 0; // 重试次数

    // 上传文件
    this.upload = function (file, onProgress, onError, onComplete) {
        this.file = file;

        // 配置上传参数
        const config = {
            useCdnDomain: true, // 使用CDN加速
            region: qiniu.region[this.config.region], // 区域
        };

        // 生成唯一的文件名（时间戳 + 随机数 + 文件后缀）
        const key = `upload_${Date.now()}_${Math.floor(Math.random() * 10000)}.${file.name.split('.').pop()}`;

        // 创建上传任务
        const observable = qiniu.upload(
            file,
            key,
            this.config.uploadToken,
            {}, // 可选参数
            config // 配置
        );

        // 监听上传进度
        observable.subscribe({
            next: (response) => {
                // 计算上传进度
                const percent = Math.round(response.total.percent);
                onProgress && onProgress(percent);
            },
            error: (err) => {
                // 上传失败
                onError && onError(err);
            },
            complete: (res) => {
                // 上传完成，生成文件访问地址
                const fileUrl = `${this.config.domain}/${res.key}`;
                onComplete && onComplete(fileUrl);
            }
        });
    };

    // 暂停上传
    this.pause = function () {
        this.isPaused = true;
    };

    // 恢复上传
    this.resume = function () {
        this.isPaused = false;
        this.upload(this.file); // 重新开始上传
    };
}