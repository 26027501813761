/*
 * @Author: your name
 * @Date: 2021-06-19 13:31:36
 * @LastEditTime: 2021-08-14 10:20:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /call_manage_system_vue/src/api/call_api/index.js
 */
import request from "@/utils/request";

//公司 查询通话记录列表 汇总数据
export function getCompanyCallList(query) {
    return request({
        url: "/business/callBack/companyCallBackList",
        // url: "companyCallBackList",
        method: "get",
        params: query,
    });
}

//部门 查询通话记录列表 汇总数据
export function getDeptCallList(query) {
    return request({
        url: "/business/callBack/deptCallBackList",
        method: "get",
        params: query,
    });
}

//个人 查询通话记录列表 汇总数据
export function getDetailList(query) {
    return request({
        url: "/business/callBack/detailList",
        method: "get",
        params: query,
    });
}

//通话记录员工具体数据列表 详细数据
export function getCallDetailList(query) {
    return request({
        url: "/business/callBack/detailList",
        method: "get",
        params: query,
    });
}
//通话记录员工具体数据列表 详细数据
export function getCallBookDetailList(query) {
    return request({
        url: "/callBook/recordList",
        method: "get",
        params: query,
    });
}
// 查询充值记录列表
export function handleMakeCall(data) {
    return request({
        url: "/business/callBack/makeCall",
        method: "post",
        data: data,
    });
}

// 查询充值记录详细
export function getBill(id) {
    return request({
        url: "/business/bill/" + id,
        method: "get",
    });
}

// 工作台 查询今日数据
export function getWorkBenchData(userId) {
    return request({
        url: "/business/callBack/workbench/" + userId,
        method: "get",
    });
}

// 根据手机号查询归属地信息
export function getLocationByPhone(phone) {
    return request({
        url: "/business/CallPhoneLocation/" + phone,
        method: "get",
    });
}

//公司账单 查询通话记录列表
export function getCompanyBillList(data) {
    return request({
        url: "/business/userDuration/listBill",
        // url: "/business/callCompanyBilling/list",
        method: "post",
        data: data,
    });
}

//公司时长账单列表
export function getUserDurationList(data) {
    return request({
        url: "/business/userDuration/list",
        method: "post",
        data: data,
    });
}

//公司时长账单表格
export function getUserCountData(data) {
    return request({
        url: "/business/userDuration/listOne",
        method: "post",
        data: data,
    });
}

// 发送单词短信 
export function sendSmsSendSingle(data) {
    return request({
        url: "/business/sms/smsSendSingle",
        method: "post",
        data: data,
    });
}

// 发送单词短信 
export function makeCallSip(data) {
    return request({
        url: '/business/callBack/makeCallSip',
        method: "post",
        data: data,
    });
}

// 发送callid
export function makeCallsipV2(data) {
    return request({
        url: '/business/callBack/makeCallSipV2',
        method: "post",
        data: data,
    });
}
// 获取公司账单日信息
export function getStatementDate(id) {
    return request({
        url: '/seat/getStatementDate/' + id,
        method: "get",
    });
}

// 根据手机号查询归属地信息
export function callPhoneAddress(phone) {
    return request({
        url: "/business/callPhoneAddress/" + phone,
        method: "get",
    });
}