<!--
 * @Author: dinghao dinghao361@gmail.com
 * @Date: 2023-08-05 18:11:11
 * @LastEditors: dinghao dinghao361@gmail.com
 * @LastEditTime: 2023-08-13 19:47:10
 * @FilePath: /qiyu-official-website-vue/src/components/CusInput.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="cus-input">
        <div class="cus-input-con">
            <div class="cus-input-con-box" v-if="type == 'password'">
                <input class="pswinput" :maxlength="maxlength" @change="$emit('handleVerify')"
                    @blur="$emit('handleVerify')" :type="showEye ? 'text' : 'password'" :value="modelValue"
                    :placeholder=placeholder v-model="modelValue">
                <!--  @input="$emit('update:modelValue', $event.target.value), $emit('handleVerify')" -->
                <span @click="toggleShowEye" :class="showEye ? 'show-eye active' : 'show-eye'"></span>
            </div>
            <input v-else :maxlength="maxlength" @change="$emit('handleVerify')" @blur="$emit('handleVerify')"
                :type="type" :value="modelValue" v-model="modelValue" :placeholder=placeholder>
            <!-- 
                @input="$emit('update:modelValue', $event.target.value), $emit('handleVerify')" -->
        </div>
        <div class="err-hint" v-if="verify.isShow">{{ verify.hint }}</div>
    </div>

</template>
<script>
export default {
    data() {
        return {
            showEye: false,
        }
    },
    computed: {
        title() {
            return this.type === 1 ? "用户服务协议" : this.$webTitle+"个人信息保护政策"
        },
        modelValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit("change", value)
            }
        }
    },
    watch: {
    },
    props: {
        placeholder: {
            type: String,
            default: "请输入手机号"
        },
        type: {
            type: String,
            default: "text"
        },
        verify: {
            type: Object,
            default: () => {
                return {
                    hint: "",
                    isShow: false
                }
            }
        },
        maxlength: {
            type: Number
        },
        value: {},
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    methods: {
        toggleShowEye() {
            let showEye = JSON.parse(JSON.stringify(this.showEye))
            this.showEye = !showEye;
        }
    }
}
</script>
<!-- <script setup>
// import { defineProps, defineEmits} from "vue"
import { ref, reactive, toRefs, onBeforeUnmount, onMounted } from "vue"
defineEmits(['update:modelValue', "handleVerify"])
defineProps({
    placeholder: {
        type: String,
        default: "请输入手机号"
    },
    type: {
        type: String,
        default: "text"
    },
    verify: {
        type: Object,
        default: () => {
            return {
                hint: "",
                isShow: false
            }
        }
    },
    maxlength: {
        type: Number
    },
    modelValue: {},
})
let showEye = ref(false)
const showEyeObj = reactive({
    data: {
        showEye: false,
    }
});
const toggleShowEye = () => {
    let showEye = showEyeObj.data.showEye
    console.log(showEye)
    showEyeObj.data.showEye = !showEye;
}
</script> -->

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cus-input {
    position: relative;

    .err-hint {
        font-size: 12px;
        color: #f12525;
        padding-top: 2px;
        padding-bottom: 2px;
        position: absolute;
        top: 100%;
        left: 0;
    }
}

.cus-input-con {
    width: 100%;
    height: 55px;
    background: #F6F8FB;
    border-radius: 6px;
    // padding: 0 12px;
    box-sizing: border-box;

    // border: 2px solid #2171FE;
    .cus-input-con-box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
    }

    .show-eye {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url('~@/assets/img/home/eye_close.png');
        background-size: 100%;
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 18px;
    }

    .show-eye.active {
        background-image: url('~@/assets/img/home/eye_open.png');
    }

    input {
        width: 100%;
        height: 100%;
        font-size: 18px;
        text-indent: 1em;
        border-radius: 6px;

        &::-webkit-input-placeholder {
            font-size: 18px;
            color: #999999;
            font-weight: 400;
        }
    }
}

input {
    width: 80%;
    height: 100%;
    border: 0;
    background: 0;
    padding: 0;
    margin: 0;
}
</style>
