<!--
 * @Author: dinghao dinghao361@gmail.com
 * @Date: 2023-07-28 23:39:42
 * @LastEditors: dinghao dinghao361@gmail.com
 * @LastEditTime: 2023-07-29 00:20:31
 * @FilePath: /call_manage_system_vue/src/views/system/user/profile/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="app-container">
        <el-card class="box-card">
            <el-descriptions title="" :column="3">
                <div slot="title" class="clearfix">
                    <span class="line"></span>
                    <span>个人信息</span>
                </div>
                <el-descriptions-item label="头像">
                    <userAvatar :user="user" />
                </el-descriptions-item>
                <el-descriptions-item label="账号">{{ user.userName }}</el-descriptions-item>
                <el-descriptions-item label="公司-部门">
                    <span v-if="user.dept">{{ user.dept.deptName }} / {{ postGroup || "--" }}</span>
                </el-descriptions-item>
                <el-descriptions-item label="角色">{{ roleGroup || '暂无' }}
                </el-descriptions-item>
                <el-descriptions-item label="创建日期">{{ user.createTime }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="资料修改" :column="3" style="margin-top: 50px;" :colon="false">
                <div slot="title" class="clearfix">
                    <span class="line"></span>
                    <span>资料修改</span>
                </div>
                <el-descriptions-item label="">
                    <resetPwd :user="user" />
                </el-descriptions-item>
            </el-descriptions>
        </el-card>
    </div>
</template>

<script>
import userAvatar from "./userAvatar";
import userInfo from "./userInfo";
import resetPwd from "./resetPwd";
import { getUserProfile } from "@/api/system/user";

export default {
    name: "Profile",
    components: { userAvatar, userInfo, resetPwd },
    data() {
        return {
            user: {},
            roleGroup: {},
            postGroup: {},
            activeTab: "resetPwd",
        };
    },
    created() {
        this.getUser();
    },
    methods: {
        getUser() {
            getUserProfile().then((response) => {
                this.user = response.data;
                this.roleGroup = response.roleGroup;
                this.postGroup = response.postGroup;
            });
        },
    },
};
</script>
<style scoped>
.el-card {
    border-radius: 20px;
}

/deep/ .el-descriptions-item__label {
    width: auto !important;
}
</style>
