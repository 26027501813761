
import { EcpAutoRecycle } from "@/api/business/ecp";

export function canFuji(row, that) {
    if (row.msg == '当前ecp号码处于停机状态') {
        that.$confirm('当前ecp号码处于停机状态,是否进行复机操作?', '提示', {
            confirmButtonText: '复机',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            let userInfo = window.localStorage.getItem('userInfo')
            if (userInfo) {
                userInfo = JSON.parse(userInfo)
                EcpAutoRecycle({ companyId: userInfo.companyId, ecpNumber: row.ecpNumber }).then((response) => {
                    that.msgSuccess("复机成功");
                });
            }
        }).catch(() => {
        });
    }
}