/*
 * @Author: your name
 * @Date: 2021-06-21 22:13:57
 * @LastEditTime: 2021-07-12 15:44:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /call_manage_system_vue/src/api/business/company.js
 */
import request from "@/utils/request";

// 查询公司坐席管理列表
export function listSeatCompany(query) {
    return request({
        url: "/seat/pageList",
        method: "post",
        params: query
    });
}
// 公司坐席管理批量添加
export function addSeatCompany(data) {
    return request({
        url: "/seat/addMult",
        method: "post",
        data: data
    });
}
// 公司坐席管理续费
export function addSeatRenew(data) {
    return request({
        url: "/seat/renew",
        method: "post",
        data: data
    });
}

// 查询公司
export function listCompany(query) {
    return request({
        url: "/business/company/list",
        method: "get",
        params: query
    });
}

// 修改公司
export function updateDept(data) {
    return request({
        url: "/system/dept",
        method: "put",
        data: data
    });
}

// 查询公司详细
export function getCompany(deptId) {
    return request({
        url: "/system/dept/" + deptId,
        method: "get"
    });
}

// 新增公司
export function addCompany(data) {
    return request({
        url: "/system/dept",
        method: "post",
        data: data
    });
}

// 删除部门
export function delCompany(deptId) {
    return request({
        url: "/system/dept/" + deptId,
        method: "delete"
    });
}

// 查询通话线路类型列表
export function listLineTypes(query) {
    return request({
        url: "/business/lineType/list",
        method: "get",
        params: query
    });
}
// 短信充值
export function getSmsRecharge(data) {
    return request({
        url: "/business/sms/smsRecharge",
        method: "post",
        data: data,
    });
}

// 话费充值
export function recharge(data) {
    return request({
        url: "/business/bill/saveRecharge",
        method: "post",
        data: data
    });
}
export function getCallUserPhoneByUserld(data) {
    return request({
        url: "/business/isCurrentPhone/getCallUserPhoneByUserld",
        method: "post",
        data: data
    });
}
export function addCompanyConfig(data) {
    return request({
        url: "/system/dept/updateCallConfig",
        method: "get",
        params: data
    });
}
//扣费
export function chargingAmount(data) {
    return request({
        url: "/business/company/deduction",
        method: "post",
        data: data
    });
}

//公司线路新增
export function insertDeptCallType(data) {
    return request({
        url: "/system/dept/insertDeptCallType",
        method: "post",
        data: data
    });
}

//公司线路删除
export function delDeptCallType(id) {
    return request({
        url: "/system/dept/delDeptCallType?id=" + id,
        method: "delete"
    });
}

//公司线路查询
export function findCallType(data) {
    return request({
        url: "/system/dept/findCallType",
        method: "get",
        params: data
    });
}
// 线路下拉查询
export function getLineALl() {
    return request({
        url: "business/isCurrentPhone/getLineALl",
        method: "get",
    });
}

// 获取统计
export function getStatisticsInfo() {
    return request({
        url: "/business/tj/home",
        method: "get",
    });
}
// 获取代理商页面的折线图
export function getBrokenLine() {
    return request({
        url: "/business/tj/brokenLine",
        method: "get",
    });
}
//公司管理页面上传合同
export function uploadContractFile(data) {
    return request({
        url: "/business/company/uploadContractFile",
        method: "post",
        data: data
    });
}
//公司管理页面获取公司合同
export function downloadContractFile(data) {
    return request({
        url: "/business/company/getContractFile",
        method: "get",
        params: data
    });
}

//修改公司扩展信息-销售-客服负责人-支付-消费-保证金等
export function updateCompanyExtraInfo(data) {
    return request({
        url: "/business/company/edit",
        method: "post",
        data: data
    });
}



//客情维护
export function getKeqingWeihu(data) {
    return request({
        url: "/business/company/list/v2",
        method: "get",
        params: data
    });
}
// 成交记录接口
export function transactionRecord(data) {
    return request({
        url: "/business/transactionRecord/list",
        method: "get",
        params: data
    });
}

// 提交成交记录接口
export function transactionRecordPost(data) {
    return request({
        url: "business/transactionRecord",
        method: "post",
        data: data
    });
}
//校验黑名单
export function getBrokenLine4() {
    return request({
        url: "/business/phoneCheck/getRiskInfo",
        method: "post",
    });
}
//获取外呼错误码
export function getBrokenLine5() {
    return request({
        url: "/business/phoneCheck/getResultCodes",
        method: "get",
    });
}
// 绑定机器人/api/business/robot/bindCompanyAdmin
export function bindCompanyAdmin(data) {
    return request({
        url: "/business/robot/bindCompanyAdmin",
        method: "post",
        data: data
    });
}
//获取测试信息
export function getTestUser() {
    return request({
        url: "/business/lineType/huibo",
        method: "get",
    });
}
//获取平台线路消耗时间
export function callMinutes(data) {
    return request({
        url: "/business/company/callMinutes",
        method: "post",
        data
    });
}

//获取坐席套餐分钟数统计
export function getSeatStatData(data) {
    return request({
        url: "/business/stat/getSeatStatData",
        method: "post",
        data
    });
}


// 线路查询
export function getRoutelist(data) {
    return request({
        url: "/business/route/list",
        method: "get",
        params: data
    });
}
// 线路新增
export function addRoute(data) {
    return request({
        url: "/business/route",
        method: "post",
        data: data
    });
}
// 线路修改
export function updateRoute(data) {
    return request({
        url: "/business/route/update",
        method: "post",
        data: data
    });
}
// 线路删除
export function deleteRoute(id) {
    return request({
        url: "/business/route/" + id,
        method: "delete",
    });
}

// 获取运营商下线路
export function routegetLineAll() {
    return request({
        url: "/business/route/getLineAll",
        method: "get",
    });
}
// 新增公司线路关系
export function addCallLine() {
    return request({
        url: "/business/route/addCallLine",
        method: "post",
        data: data
    });
}
// 公司线路关系删除
export function delCallLine(id) {
    return request({
        url: "/business/route/delCallLine/" + id,
        method: "delete",
    });
}

// 查询公司配置的线路
export function routefindCallType() {
    return request({
        url: "/business/route/findCallType",
        method: "get",
    });
}
// 公司管理，线路需要支持编辑
export function updateDeptCallType(data) {
    return request({
        url: "/system/dept/updateDeptCallType",
        method: "post",
        data
    });
}