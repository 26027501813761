// 子组件内容
<template>
    <div class="image-upload-multiple">
        <!-- 身份证上传组件 -->
        <el-upload
            ref="uploader"
            list-type="picture-card"
            :class="{ hide: hideUploadBtn }"
            :auto-upload="autoUpload"
            :disabled="disabled"
            :action="action"
            :name="name"
            :data="data"
            :http-request="request"
            :file-list="defaultFileList"
            :show-file-list="showFileList"
            :accept="accept"
            :multiple="multiple"
            :limit="limit"
            :before-upload="before"
            :before-remove="beforeRemove"
            :on-remove="remove"
            :on-success="success"
            :on-error="error"
            :on-preview="handlePreview"
            :on-exceed="handleExceed"
        >
            <img :src="img" alt="上传身份证图片" class="ident_img" />
            <template #tip>
                <div v-if="tip" class="el-upload__tip">{{ tip }}</div>
            </template>
            <template #file="{ file }">
                <div class="image-upload-list-item">
                    <el-image
                        class="el-upload-list__item-thumbnail"
                        :src="file.url"
                        fit="cover"
                        :preview-src-list="preview"
                        :initial-index="preview.findIndex((n) => n == file.url)"
                        hide-on-click-modal
                        append-to-body
                        :z-index="9999"
                    >
                        // 图片展示，正在展示加载中样式
                        <template #placeholder>
                            <div class="image-upload-multiple-image-slot">
                                Loading...
                            </div>
                        </template>
                    </el-image>
                    <div
                        v-if="!disabled && file.status == 'success'"
                        class="image-upload__item-actions"
                    >
                        // 右上角的移除功能
                        <span class="del" @click="handleRemove(file)">
                            <i class="el-icon-delete"></i>
                        </span>
                    </div>
                    <div
                        v-if="
                            file.status == 'ready' || file.status == 'uploading'
                        "
                        class="image-upload__item-progress"
                    >
                        // 正在上传的进度条
                        <el-progress
                            :percentage="file.percentage"
                            :text-inside="true"
                            :stroke-width="16"
                        />
                    </div>
                </div>
            </template>
        </el-upload>
        <span style="display: none !important">
            //这里展示的是上传成功后后端返路径名
            <el-input v-model="value"></el-input>
        </span>
    </div>
</template>

<script>
import config from "@/components/miniQnImageUpload/config.js"; // config配置文件，后面会给出
import Sortable from "sortablejs"; // 这是一个拖拽的插件库

export default {
    props: {
        // 子组件接收参数
        modelValue: { type: String, default: "" }, // 接收后端返回的文件路径名
        tip: { type: String, default: "" }, // 接收提示
        action: { type: String, default: "" }, // 接收上传的地址
        apiObj: { type: Object, default: () => {} }, // 接收了api请求对象(后续会给出)
        name: { type: String, default: config.filename }, // 接收文件名
        data: { type: Object, default: () => {} }, // 接收上传时附带的额外参数
        accept: { type: String, default: "image/gif, image/jpeg, image/png" }, //文件类型
        maxSize: { type: Number, default: config.maxSize }, // 接收最大上传文件大小
        limit: { type: Number, default: 0 }, // 接收最大文件数量
        autoUpload: { type: Boolean, default: true }, // 接收是否支持自动上传
        showFileList: { type: Boolean, default: true }, // 接收展示的文件列表
        multiple: { type: Boolean, default: true }, // 接收是否支持
        disabled: { type: Boolean, default: false }, // 接收是否禁用
        draggable: { type: Boolean, default: false }, //接收是否支持拖拽
        onSuccess: {
            // 接收成功返回的函数
            type: Function,
            default: () => {
                return true;
            },
        },
        type: {
            // 身份证正反面
            type: String,
            default: "front",
        },
    },
    data() {
        return {
            value: "", // 上传成功的路径名value存储
            defaultFileList: [], // 首次渲染展示的图片列表
            hideUploadBtn: false, // 是否展示按钮标记
            img: "", // 按钮背景图片路径
        };
    },
    // 超级牛
    model: {
        prop: "modelValue",
        event: "change",
    },
    watch: {
        modelValue(val) {
            if (val !== this.toStr(this.defaultFileList)) {
                this.defaultFileList = this.toArr(val);
                this.value = val;
            }
        },
        defaultFileList: {
            // 监听图片列表
            handler(val) {
                this.$emit("change", this.toStr(val));
                this.value = this.toStr(val);
            },
            deep: true,
        },
    },
    computed: {
        preview() {
            // 预览图片路径
            return this.defaultFileList.map((v) => v.url);
        },
    },
    mounted() {
        this.value = this.modelValue; // 初始赋值图片路径
        this.defaultFileList = this.toArr(this.modelValue);
        if (!this.disabled && this.draggable) {
            // 是否配置拖拽
            this.rowDrop();
        }
        if (this.type === "front") {
            // 按钮背景图片正反面
            this.img = require("@/assets/img/ident_front.png");
        } else {
            this.img = require("@/assets/img/ident_reverse.png");
        }
    },
    methods: {
        toArr(str) {
            // 将默认值转换为数组
            var _arr = [];
            var arr = str.split(",");
            arr.forEach((item) => {
                if (item) {
                    var urlArr = item.split("&name=");
                    var fileName = urlArr[urlArr.length - 1];
                    _arr.push({
                        name: fileName,
                        url: item,
                    });
                }
            });
            this.hideUploadBtn = _arr.length >= this.limit; // 该表达式给hideUploadBtn返回的布尔值目的是当上传文件超过限制时需要隐藏上传按钮
            return _arr;
        },
        toStr(arr) {
            // 将数组转换为原始值
            return arr.map((v) => v.url).join(",");
        },
        rowDrop() {
            // 拖拽函数
            const _this = this;
            const itemBox =
                this.$refs.uploader.$el.querySelector(".el-upload-list");
            Sortable.create(itemBox, {
                handle: ".el-upload-list__item",
                animation: 200,
                ghostClass: "ghost",
                onEnd({ newIndex, oldIndex }) {
                    const tableData = _this.defaultFileList;
                    const currRow = tableData.splice(oldIndex, 1)[0];
                    tableData.splice(newIndex, 0, currRow);
                },
            });
        },
        before(file) {
            // 上传文件之前,限制文件类型、大小
            if (!["image/jpeg", "image/png", "image/gif"].includes(file.type)) {
                this.$message.warning(
                    `选择的文件类型 ${file.type} 非图像类文件`
                );
                return false;
            }
            const maxSize = file.size / 1024 / 1024 < this.maxSize;
            if (!maxSize) {
                this.$message.warning(
                    `上传文件大小不能超过 ${this.maxSize}MB!`
                );
                return false;
            }
        },
        success(res, file, fileList) {
            // 上传文件成功后处理文件路径
            var os = this.onSuccess(res, file);
            if (os !== undefined && os === false) {
                return false;
            }
            var response = config.parseData(res);
            file.url = response.src.data;
            this.defaultFileList = fileList;
            this.hideUploadBtn = fileList.length >= this.limit; // 上传成功需要再次赋值，控制按钮的展示隐藏
        },
        remove(file, fileList) {
            // 移除成功时的函数
            this.defaultFileList = fileList;
            this.hideUploadBtn = fileList.length >= this.limit; // // 移除成功需要再次赋值，控制按钮的展示隐藏
        },
        error(err) {
            // 上传错误的信息
            this.$notify.error({
                title: "上传文件未成功",
                message: err,
            });
        },
        beforeRemove(uploadFile) {
            // 移除之前弹窗是否确定移除
            return this.$confirm(`是否移除 ${uploadFile.name} ?`, "提示", {
                type: "warning",
            })
                .then(() => {
                    return true;
                })
                .catch(() => {
                    return false;
                });
        },
        handleRemove(file) {
            // 点击移除小图标调用的函数
            this.$refs.uploader.handleRemove(file);
            // this.defaultFileList.splice(this.defaultFileList.findIndex(item => item.uid===file.uid), 1)
        },
        handleExceed() {
            // 文件超出个数限制时的钩子
            this.$message.warning(
                `当前设置最多上传 ${this.limit} 个文件，请移除后上传!`
            );
        },
        handlePreview(uploadFile) {
            // 文件预览
            window.open(uploadFile.url); // 通过windows
        },
        request(param) {
            // 自定义上传的实现
            var apiObj = config.apiObj; //这里是配置的api上传方法
            if (this.apiObj) {
                apiObj = this.apiObj;
            }
            const data = new FormData();
            data.append(param.filename, param.file);
            for (const key in param.data) {
                data.append(key, param.data[key]);
            }
            apiObj
                .upload(data, {
                    onUploadProgress: (e) => {
                        const complete = parseInt(
                            ((e.loaded / e.total) * 100) | 0,
                            10
                        );
                        param.onProgress({ percent: complete });
                    },
                })
                .then((res) => {
                    param.onSuccess(res);
                })
                .catch((err) => {
                    param.onError(err);
                });
        },
    },
};
</script>

<style lang="less" scoped>
.hide {
    // 注意这里是控制按钮展示隐藏的
    ::v-deep .el-upload--picture-card {
        display: none !important;
    }
}
//----这里控制的按钮背景图片的样式
.ident_img {
    width: 140px;
    height: 88px;
    background-color: #fff;
}
::v-deep .el-upload--picture-card {
    border: none;
    width: 140px;
    height: 88px;
    line-height: 88px;
    padding: 0 !important;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
    width: 140px;
    height: 88px;
    line-height: 88px;
}
// ----到这
.el-form-item.is-error .image-upload-multiple:deep(.el-upload--picture-card) {
    border-color: var(--el-color-danger);
}
:deep(.el-upload-list__item) {
    transition: none;
    border-radius: 0;
}
.image-upload-multiple:deep(.el-upload-list__item.el-list-leave-active) {
    position: static !important;
}
.image-upload-multiple:deep(.el-upload--picture-card) {
    border-radius: 0;
}
.image-upload-list-item {
    width: 100%;
    height: 100%;
    position: relative;
}
.image-upload-multiple .el-image {
    display: block;
}
.image-upload-multiple .el-image:deep(img) {
    -webkit-user-drag: none;
}
.image-upload-multiple-image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 12px;
}
.image-upload-multiple .el-upload-list__item:hover .image-upload__item-actions {
    display: block;
}
.image-upload__item-actions {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
}
.image-upload__item-actions span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: #fff;
}
.image-upload__item-actions span i {
    font-size: 12px;
}
.image-upload__item-actions .del {
    background: #f56c6c;
}
.image-upload__item-progress {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--el-overlay-color-lighter);
}
</style>
