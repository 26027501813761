import request from '@/utils/request'

// 查询资源列表
export function listCrmResourceList(query) {
    return request({
        url: '/business/crmResourceList/list',
        method: 'get',
        params: query
    })
}

// 查询资源列详细
export function getCrmResourceList(id) {
    return request({
        url: '/business/crmResourceList/' + id,
        method: 'get'
    })
}

// 新增资源列
export function addCrmResourceList(data) {
    return request({
        url: '/business/crmResourceList',
        method: 'post',
        data: data
    })
}

// 修改资源列
export function updateCrmResourceList(data) {
    return request({
        url: '/business/crmResourceList',
        method: 'put',
        data: data
    })
}

// 删除资源列
export function delCrmResourceList(id) {
    return request({
        url: '/business/crmResourceList/' + id,
        method: 'delete'
    })
}
export function delCrmResourceListNew(data) {
    return request({
        url: '/business/crmResourceList/deleteAll',
        method: 'post',
        data: data
    })
}
// 批量导入资源弹窗
export function exportResource(query) {
    return request({
        url: '/business/crmResourceList/exportResource2',
        method: 'get',
        params: query
    })
}
//导出
export function exportTable(query) {
    return request({
        url: '/business/crmResourceList/exportResource',
        method: 'get',
        params: query
    })
}
// 导出资源列
export function exportCrmResourceList(query) {
    return request({
        url: '/business/crmResourceList/export',
        method: 'get',
        params: query
    })
}


// 批量导入
export function importResource(data) {
    return request({
        url: '/business/crmResourceList/importResource',
        method: 'post',
        data: data
    })
}

// 查询公海资源列表
export function oceanList(query) {
    return request({
        url: '/business/crmResourceList/oceanList',
        method: 'get',
        params: query
    })
}
// 查询我的客户资源列表
export function myResourceList(query) {
    return request({
        url: '/business/crmResourceList/myResourceList',
        method: 'get',
        params: query
    })
}
// 查询我的客户资源列表 和淘客户列表 和跟进列表接口 三合一
export function resourceListV2(query) {
    return request({
        url: '/business/crmResourceList/listV2',
        method: 'get',
        params: query
    })
}

// 分配资源给销售员
export function dispToSeller(data) {
    return request({
        url: '/business/crmResourceList/dispToSeller',
        method: 'post',
        data: data
    })
}

// 批量分配资源
export function dispToSellerBatch(data) {
    return request({
        url: '/business/crmResourceList/dispToSellerBatch',
        method: 'post',
        data: data
    })
}



// 现价到公海
export function pushToOcean(data) {
    return request({
        url: '/business/crmResourceList/pushToOcean',
        method: 'post',
        data
    })
}
// 从公海取回
export function pullFromOcean(id) {
    return request({
        url: '/business/crmResourceList/pullFromOcean/' + id,
        method: 'post'
    })
}
// 从公海取回
export function pullFromOceanBatch(data) {
    return request({
        url: '/business/crmResourceList/pullFromOceanBatch',
        method: 'post',
        data
    })
}
// 修改资源分组
export function changeGroup(data) {
    return request({
        url: '/business/crmResourceList/changeGroup',
        method: 'post',
        data
    })
}
// 修改资源分组
export function crmResourceList(data) {
    return request({
        url: '/business/crmResourceList',
        method: 'put',
        data
    })
}

// 锁定至公海
export function lockToOcean(id) {
    return request({
        url: '/business/crmResourceList/lockToOcean/' + id,
        method: 'post'
    })
}
// 解锁至公海
export function unlockToOcean(id) {
    return request({
        url: '/business/crmResourceList/unlockToOcean/' + id,
        method: 'post'
    })
}
// 获取淘客户列表
export function myResourceListNoPage(data) {
    return request({
        url: '/business/crmResourceList/myResourceListNoPage',
        method: 'post',
        data
    })
}

//淘客户列表分页
export const resoucePag = (data) => {
    return request({
        url: "/business/crmResourceList/resourcePage",
        method: 'post',
        params: data
    })
}
// 淘到客户
export function getResource(data) {
    return request({
        url: '/business/crmResourceList/getResource',
        method: 'post',
        data
    })
}
// 淘到客户
export function signResource(data) {
    return request({
        url: '/business/crmResourceList/signResource',
        method: 'post',
        data
    })
}

// 待开发客户
export function toBeDevelopedList(params) {
    return request({
        url: '/business/crmResourceList/toBeDevelopedList',
        method: 'get',
        params
    })
}
//资源回收
export function retrieveResource(data) {
    return request({
        url: '/business/crmResourceList/retrieveResource',
        method: 'post',
        data
    })
}

//资源交接
export function customerHandover(data) {
    return request({
        url: '/business/crmResourceList/customerHandover',
        method: 'post',
        data
    })
}

//资源分类删除
export function deleteCrmResourceClass(id) {
    return request({
        url: '/business/callResourceClass/remove/' + id,
        method: 'delete',

    })
}
//资源导入结果查询
export function importResult(id) {
    return request({
        url: '/business/crmResourceList/importResult/' + id,
        method: 'get',

    })
}

//资源导入结果查询
export function asrReconize(id) {
    return request({
        url: '/business/crmResourceList/asrReconize/' + id,
        method: 'get',

    })
}
// 公司详情
export function getCompanyDetail(query) {
    if (process.env.NODE_ENV === 'production') {
        // 正式环境
        return request({
            baseURL: "https://gongshang.iqiyu.net:9999/api",//"http://120.26.193.103:9999/api",
            url: '/company/api/list',
            method: 'get',
            params: query
        })

    } else if (process.env.NODE_ENV === 'test') {
        //线上测试环境
        return request({
            baseURL: "https://gongshang.1qiyu.net:9999/api",//"http://120.26.193.103:9999/api",
            url: '/company/api/list',
            method: 'get',
            params: query
        })
    }

}

//资源交接
export function addResource(data) {
    return request({
        url: '/business/crmResourceList/addV2',
        method: 'post',
        data
    })
}

// 我的客户查询员工数据列表
export function getStaffData(id) {
    return request({
        url: '/system/user/listUserByCompanyId',
        method: 'get',
    })
}



