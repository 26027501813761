import request from "@/utils/request";

// 新增ECP功能号码
export function addEcpNumber(query) {
    return request({
        url: "/business/number",
        method: "post",
        data: query,
    });
}

// 获取功能号码详细信息
export function getUserList(query) {
    return request({
        url: "/business/number",
        method: "get",
        params: query,
    });
}

// 根据手机号删除功能号码  
export function deleteEcpNumber(phones) {
    return request({
        url: "/business/number/" + phones,
        method: "delete",
    });
}

// ECP自助复机接口
export function EcpAutoRecycle(data) {
    return request({
        url: "/business/number/autoRecycle",
        method: "post",
        data: data,
    });
}

// 批量号码状态查询接口
export function batchQueryEcpNumberStatus(data) {
    return request({
        url: "/business/number/batchQueryEcpNumberStatus",
        method: "post",
        data: data,
    });
}
// 查询功能号码列表  
export function listEcpNumber(query) {
    return request({
        url: "/business/number/list",
        method: "get",
        params: query,
    });
}
// 更换能力号为这个接口:
export function businessNumberUpdate(data) {
    return request({
        url: "/business/number/update",
        method: "post",
        data: data,
    });
}
// 导出列表
export function listExportNumber(data) {
    return request({
        url: "/business/crmResourceList/exportResourceRecords",
        method: "post",
        data: data,
    });
}
// 导入列表
export function getlistExportNumber(data) {
    return request({
        url: "/business/crmResourceList/importResourceRecords",
        method: "post",
        data: data,
    });
}
// 白名单管理
export function listWhiteNumber(query) {
    return request({
        url: "/business/phoneList/list",
        method: "get",
        params: query,
    });
}
// 新增白名单号码
export function addWhiteNumber(query) {
    return request({
        url: "/business/phoneList/insert",
        method: "post",
        data: query,
    });
}
// 获取坐席数量统计
export function getCompanySeats(query) {
    return request({
        url: "/business/callBack/getCompanySeats",
        method: "get",
        data: query,
    });
}

// 编辑

export function editWhiteNumber(query) {
    return request({
        url: "/business/phoneList/edit",
        method: "post",
        data: query,
    });
}
// 根据手机号删除白名单号码
export function deleteWhiteNumber(phones) {
    return request({
        url: "/business/phoneList/" + phones,
        method: "delete",
    });
}