<!--
 * @Author: your name
 * @Date: 2021-07-02 21:52:28
 * @LastEditTime: 2021-07-09 23:48:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /call_manage_system_vue/src/views/business/lineManage.vue
-->
<template>
    <div class="app-container">
        <!-- 查询表单 -->
        <div class="action-box">
            <div class="action-title">
                <span>运营商管理查询</span>
            </div>
            <el-form
                :model="queryParams"
                ref="queryForm"
                :inline="true"
                v-show="showSearch"
                label-width="120px"
            >
                <el-form-item label="运营商名称" prop="operatorId">
                    <el-select
                        v-model="queryParams.operatorId"
                        placeholder="请选择运营商"
                        @change="handleQuery"
                    >
                        <el-option
                            v-for="item in lineAlls"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        size="mini"
                        @click="handleQuery"
                        >搜索</el-button
                    >
                    <el-button
                        icon="el-icon-refresh"
                        size="mini"
                        @click="resetQuery"
                        >重置</el-button
                    >
                </el-form-item>
                <el-form-item style="">
                    <div
                        style="
                            line-height: 20px;
                            margin: 0px 35px;
                            font-size: 14px;
                            margin: 0px 35px;
                            font-size: 14px;
                        "
                    >
                        <span style="color: #2171fe">
                            请注意:<br />
                            1 每条线路优势和劣势有所区别，
                            请仔细核对是否满足业务需求
                            <br />2测试线路只作为测试拨打模式使用，以实际使用线路以下表为准。
                        </span>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <div class="table-box-content">
            <el-row :gutter="10" class="mb8">
                <el-col :span="1.5">
                    <el-button
                        type="primary"
                        plain
                        icon="el-icon-plus"
                        size="mini"
                        @click="handleAdd"
                        v-hasPermi="['business:route:add']"
                        >新增</el-button
                    >
                </el-col>
                <!-- <el-col :span="1.5">
                    <el-button
                        type="success"
                        plain
                        icon="el-icon-edit"
                        size="mini"
                        :disabled="single"
                        @click="handleUpdate"
                        v-hasPermi="['business:bill:edit']"
                        >修改</el-button
                    >
                </el-col> -->
                <!-- <el-col :span="1.5">
                    <el-button
                        type="danger"
                        plain
                        icon="el-icon-delete"
                        size="mini"
                        :disabled="multiple"
                        @click="handleDelete"
                        v-hasPermi="['business:route:remove']"
                        >删除</el-button
                    >
                </el-col> -->
                <!-- <el-col :span="1.5">
                    <el-button
                        type="warning"
                        plain
                        icon="el-icon-download"
                        size="mini"
                        :loading="exportLoading"
                        @click="handleExport"
                        v-hasPermi="['business:bill:export']"
                        >导出</el-button
                    >
                </el-col> -->
                <right-toolbar
                    :showSearch.sync="showSearch"
                    @queryTable="getList"
                ></right-toolbar>
            </el-row>

            <el-table
                v-loading="loading"
                :data="lineTypeList"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55" align="center" />
                <!-- <el-table-column label="运营商ID" prop="operatorId" /> -->
                <el-table-column
                    label="运营商"
                    align="left"
                    show-overflow-tooltip
                    prop="operator"
                />
                <el-table-column label="线路" align="left" prop="lineName" />
                <el-table-column label="所需卡" align="left" prop="cardType" />
                <el-table-column
                    label="方式"
                    align="left"
                    width="50"
                    prop="way"
                >
                    <template slot-scope="scope">
                        <span>{{ ["回拨", "直呼"][scope.row.way - 1] }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="主叫盲区"
                    align="left"
                    width="auto"
                    prop="callerNoZone"
                />
                <el-table-column
                    label="被叫盲区"
                    align="left"
                    width="auto"
                    prop="calledNoZone"
                />
                <el-table-column
                    label="号码月租"
                    align="left"
                    prop="monthlyRent"
                />
                <el-table-column label="加白" align="left" prop="whitening" />
                <el-table-column
                    label="其他费"
                    align="center"
                    prop="rests"
                    :show-overflow-tooltip="true"
                />
                <el-table-column
                    label="查看详情"
                    align="center"
                    class-name="small-padding fixed-width"
                    width="180"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="primary"
                            icon="el-icon-edit"
                            @click="handleDetail(scope.row)"
                            v-hasPermi="['business:bill:edit']"
                            style="width: 80px"
                            >查看详情</el-button
                        >
                    </template>
                </el-table-column>

                <!-- <el-table-column label="限制" width="50" align="center" prop="cardLimit" />
                <el-table-column label="日呼量" align="left" width="100" prop="dailyCall" />
                <el-table-column label="录音" align="left" width="40" prop="recording">
                    <template slot-scope="scope">
                        <span>{{ ["否", "是"][scope.row.recording] }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="打固话" align="left" width="20" prop="fixedLine">
                    <template slot-scope="scope">
                        <span>{{ ["否", "是"][scope.row.fixedLine] }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="拨打时间" align="left" width="100" prop="callTime" />
                <el-table-column label="周期" align="left" width="100" prop="period" />
                <el-table-column label="备案材料" align="left" width="100" prop="publicRecord" />
                <el-table-column label="支持行业" align="left" width="100" prop="industry" :show-overflow-tooltip="true" />
                <el-table-column label="加白时间" align="left" width="100" prop="whiteTimeNode" />
                <el-table-column label="注意事项" align="left" width="160" prop="remark" /> -->
                <el-table-column
                    label="操作"
                    align="center"
                    class-name="small-padding fixed-width"
                    width="180"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            @click="handleUpdate(scope.row)"
                            v-hasPermi="['business:operatorManage:update']"
                            >修改</el-button
                        >
                        <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            @click="handleDelete(scope.row)"
                            v-hasPermi="['business:operatorManage:delete']"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>

            <pagination
                v-show="total > 0"
                :total="total"
                :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize"
                @pagination="getList"
            />
        </div>
        <!-- 详情 -->
        <el-dialog
            title="查看详情"
            :visible.sync="openDetail"
            width="840px"
            append-to-body
        >
            <div style="width: 100%; height: 400px; overflow-y: scroll">
                <el-descriptions
                    title=""
                    v-if="currentRow"
                    :column="2"
                    :size="size"
                    border
                >
                    <el-descriptions-item label="限制">
                        <div style="width: 180px">
                            {{ currentRow.cardLimit }}
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item label="日呼量">
                        <div style="width: 180px">
                            {{ currentRow.dailyCall }}
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item label="是否有录音">{{
                        ["否", "是"][currentRow.recording]
                    }}</el-descriptions-item>
                    <el-descriptions-item label="是否能打固话">{{
                        ["否", "是"][currentRow.fixedLine]
                    }}</el-descriptions-item>
                    <el-descriptions-item label="拨打时间">{{
                        currentRow.callTime
                    }}</el-descriptions-item>
                    <el-descriptions-item label="周期">{{
                        currentRow.period
                    }}</el-descriptions-item>
                    <el-descriptions-item label="备案材料">{{
                        currentRow.publicRecord
                    }}</el-descriptions-item>
                    <el-descriptions-item label="加白时间">{{
                        currentRow.whiteTimeNode
                    }}</el-descriptions-item>
                    <el-descriptions-item
                        label-class-name="my-label"
                        content-class-name="my-label"
                        label="注意事项"
                        :span="2"
                        >{{ currentRow.remark }}</el-descriptions-item
                    >
                    <el-descriptions-item label="支持行业" :span="2">
                        <div v-html="currentRow.industry"></div>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="openDetail = false"
                    >确 定</el-button
                >
                <el-button @click="openDetail = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- 添加或修改对话框 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="840px"
            append-to-body
        >
            <div style="width: 100%; height: 400px; overflow-y: scroll">
                <el-form
                    ref="form"
                    :model="form"
                    :rules="rules"
                    label-width="200px"
                >
                    <el-col
                        :span="
                            ['ecpCaCertPic', 'ecpCaNumPic','ecpCaNumPicBack','whiteCaPic'].includes(it)
                                ? 24
                                : 12
                        "
                        v-for="(it, index) in Object.keys(column)"
                        :key="index"
                    >
                        <el-form-item
                            :label="Object.values(column)[index]"
                            :prop="it + ''"
                        >
                            <el-select
                                v-model="form.operatorId"
                                placeholder="请选择运营商"
                                v-if="it == 'operatorId'"
                                @change="dialogNameChange"
                            >
                                <el-option
                                    v-for="item in lineAll"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                ></el-option>
                            </el-select>
                            <!-- <el-select
                                v-model="form.operatorLineType"
                                placeholder="请选择运营商"
                                v-else-if="it == 'operatorLineType'"
                            >
                                <el-option
                                    label="移动"
                                    value="移动"
                                ></el-option>
                                <el-option
                                    label="电信"
                                    value="电信"
                                ></el-option>
                            </el-select> -->
                            <el-radio-group
                                v-model="form.way"
                                v-else-if="it == 'way'"
                            >
                                <el-radio label="1">回拨</el-radio>
                                <el-radio label="2">直呼</el-radio>
                            </el-radio-group>
                            <el-radio-group
                                v-model="form.recording"
                                v-else-if="it == 'recording'"
                            >
                                <el-radio label="0">否</el-radio>
                                <el-radio label="1">是</el-radio>
                            </el-radio-group>
                            <el-radio-group
                                v-model="form.fixedLine"
                                v-else-if="it == 'fixedLine'"
                            >
                                <el-radio label="0">否</el-radio>
                                <el-radio label="1">是</el-radio>
                            </el-radio-group>
                            <!-- 上传 -->
                            <el-upload
                                class="avatar-uploader"
                                action="*"
                                :show-file-list="false"
                                :accept="'.img,.png,.jpg,.jpeg'"
                                :before-upload="beforeUploadImage"
                                :on-change="(file) => uploadImage(file,it)"
                                v-else-if="it == 'ecpCaCertPic'||it == 'ecpCaNumPic' || it == 'whiteCaPic' || it=='ecpCaNumPicBack'"
                                :auto-upload="false"
                            >
                                <img
                                    v-if="form[it]"
                                    :src="form[it]"
                                    class="avatar"
                                />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <el-input
                                v-model="form[it]"
                                :type="
                                    ['industry', 'remark'].includes(it)
                                        ? 'textarea'
                                        : 'text'
                                "
                                style="max-width: 350px"
                                v-else
                            />
                            <!-- 上传 -->
                        </el-form-item>
                    </el-col>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    getRoutelist,
    getLineALl,
    deleteRoute,
    updateRoute,
    addRoute,
    delBill,
    addBill,
    updateBill,
    exportBill,
} from "@/api/business/company";

import { qiniuPolicy } from '@/api/system/config'
import QiniuUploader from '@/utils/qiniu.js';
import QnImageUpload from "@/components/miniQnImageUpload";
import { exportLineType } from "@/api/business/lineType";
export default {
    name: "Bill",
    components: {
        QnImageUpload,
    },
    data() {
        return {
            imageUrl:null,
            lineAlls: [],
            lineAll: [],
            // 遮罩层
            loading: true,
            // 导出遮罩层
            exportLoading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 充值记录表格数据
            lineTypeList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            openDetail: false,
            // 充值方式字典
            rechargeTypeOptions: [],
            // 是否到账字典
            isArrivalOptions: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                operatorId: null,
            },
            // 表单参数
            form: {
                searchValue: null,
                createBy: "",
                createTime: "",
                updateBy: "",
                updateTime: "",
                remark: null,
                params: {},
                id: null,
                operator: "",
                way: 0,
                operatorId: "",
                cardType: "",
                callerNoZone: "",
                calledNoZone: "",
                monthlyRent: "",
                whitening: "",
                rests: "",
                blacklist: "",
                cardLimit: "",
                dailyCall: "",
                recording: 0,
                lineId: null,
                fixedLine: 0,
                callTime: "",
                lineName: "",
                period: "",
                publicRecord: "",
                industry: "",
                whiteTimeNode: "",
                operatorLineType:"",
                // whiteCaName: "", // 白名单-办卡人姓名
                // whiteCaNum: "", // 白名单-办卡人身份证号
                // whiteCaPic: "", // 白名单-办卡人资料
                // ecpCaName: "", // ecp-办卡人姓名
                // ecpCaNum: "", // ecp-办卡人身份证号
                // ecpCaCertPic: "", // ecp-办卡人证明材料
                // ecpCaNumPic: "", // ecp-办卡人身份证复印件
                // ecpCaNumPicBack:""
            },
            column: {
                operatorId: "运营商名称",
                lineName: "线路名称",
                way: "呼叫方式", // 这里您可能需要根据实际情况填写具体的值或类型
                cardType: "卡类型",
                callerNoZone: "主叫盲区",
                calledNoZone: "被叫盲区",
                monthlyRent: "号码月租费用",
                whitening: "加白费用",
                rests: "其他费用",
                cardLimit: "卡限制",
                dailyCall: "日呼量",
                recording: "录音功能", // 录音功能，0表示否，1表示是
                fixedLine: "是否打固定电话", // 是否打固定电话，0表示否，1表示是
                callTime: "拨打时间",
                period: "周期",
                publicRecord: "备案材料",
                industry: "支持行业",
                whiteTimeNode: "加白时间节点",
                remark: "其他注意事项",
                billTime: "账单时间", // 账单时间
                operatorLineType:"运营商线路类型",//
                // whiteCaName: "办卡人姓名(白名单)", // 白名单-办卡人姓名
                // whiteCaNum: "办卡人身份证号(白名单)", // 白名单-办卡人身份证号
                // whiteCaPic: "办卡人资料(白名单)", // 白名单-办卡人资料
                // ecpCaName: "办卡人姓名(ecp)", // ecp-办卡人姓名
                // ecpCaNum: "办卡人身份证号(ecp)", // ecp-办卡人身份证号
                // ecpCaCertPic: "办卡人证明材料(ecp)", // ecp-办卡人证明材料
                // ecpCaNumPic: "办卡人身份证复印件(ecp)", // ecp-办卡人身份证复印件
                // ecpCaNumPicBack:"办卡人身份证复印件反面(ecp)", // ecp-办卡人身份证复印件
            },
            // 表单校验
            rules: {
                rechargeType: [
                    {
                        required: true,
                        message: "充值方式不能为空",
                        trigger: "change",
                    },
                ],
                isArrival: [
                    {
                        required: true,
                        message: "是否到账不能为空",
                        trigger: "change",
                    },
                ],
            },
            currentRow: null,
        };
    },
    created() {
        this.getList();
        this.getlinetype();
        this.getDicts("recharge_type").then((response) => {
            this.rechargeTypeOptions = response.data;
        });
        this.getDicts("is_arrival").then((response) => {
            this.isArrivalOptions = response.data;
        });
    },
    methods: {
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex % 2 == 0) {
                return "";
            } else {
                return "success-row";
            }
        },
        handleDetail(row) {
            this.openDetail = true;
            this.currentRow = row;
        },
        getlinetype() {
            getLineALl().then((res) => {
                if (res.code == 200) {
                    this.lineAll = res.data;
                    let lines = JSON.parse(JSON.stringify(res.data));
                    lines.unshift({
                        code: null,
                        name: "全部运营商",
                    });
                    console.log("----------", lines);
                    this.lineAlls = lines;
                }
            });
            let rules = {};
            let column = Object.keys(this.column),
                values = Object.values(this.column);
            column.forEach((it, index) => {
                rules[it] = [
                    {
                        required: true,
                        message: `${values[index]}不能为空`,
                        trigger: "change",
                    },
                ];
            });
            this.rules = rules;
        },
        createTimeFormat(row) {
            if (row.createTime) {
                return row.createTime.slice(0, 10);
            } else {
                return null;
            }
        },
        updateTimeFormat(row) {
            if (row.updateTime) {
                return row.updateTime.slice(0, 10);
            } else {
                return null;
            }
        },
        dialogNameChange(e) {
            let row = this.lineAll.filter((it) => it.code == e);
            this.form.operator = row[0].name;
            console.log(this.form.operator);
        },
        /** 查询线路列表 */
        getList() {
            this.loading = true;
            getRoutelist(this.queryParams).then((response) => {
                this.lineTypeList = response.rows;
                this.total = response.total;
                this.loading = false;
            });
        },
        // 充值方式字典翻译
        rechargeTypeFormat(row, column) {
            return this.selectDictLabel(
                this.rechargeTypeOptions,
                row.rechargeType
            );
        },
        // 是否到账字典翻译
        isArrivalFormat(row, column) {
            return this.selectDictLabel(this.isArrivalOptions, row.isArrival);
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                id: null,
                companyId: null,
                companyName: null,
                rechargeAmount: null,
                beforeBalance: null,
                laterBalance: null,
                rechargeType: null,
                rechargeTime: null,
                rechargeNo: null,
                isArrival: null,
                transactionNumber: null,
                operatorLineType:null,
                // whiteCaName: "", // 白名单-办卡人姓名
                // whiteCaNum: "", // 白名单-办卡人身份证号
                // whiteCaPic: "", // 白名单-办卡人资料
                // ecpCaName: "", // ecp-办卡人姓名
                // ecpCaNum: "", // ecp-办卡人身份证号
                // ecpCaCertPic: "", // ecp-办卡人证明材料
                // ecpCaNumPic: "", // ecp-办卡人身份证复印件
                // ecpCaNumPicBack:""
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "新增线路";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.form = JSON.parse(JSON.stringify(row));
            this.open = true;
            this.title = "修改线路";
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != null) {
                        updateRoute(this.form).then((response) => {
                            this.msgSuccess("修改成功");
                            this.open = false;
                            this.getList();
                        });
                    } else {
                        addRoute(this.form).then((response) => {
                            this.msgSuccess("新增成功");
                            this.open = false;
                            this.getList();
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || this.ids;
            this.$confirm(
                '是否确认删除运营商名称为"' + row.operator + '"的数据项?',
                "警告",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(function () {
                    return deleteRoute(ids);
                })
                .then(() => {
                    this.getList();
                    this.msgSuccess("删除成功");
                });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有充值记录数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.exportLoading = true;
                    return exportBill(queryParams);
                })
                .then((response) => {
                    this.download(response.msg);
                    this.exportLoading = false;
                });
        },
        beforeUploadImage(file) {
            const isJPEG = file.name.split(".")[1] === "jpeg"; // 判断是否为 .jepg文件
            const isJPG = file.name.split(".")[1] === "jpg"; // 判断是否为 .jpg文件
            const isPNG = file.name.split(".")[1] === "png"; // 判断是否为 .png文件

            const isLt20MB = file.size / 1024 / 1024 < 20; // 判断大小是否超过20MB
            if (!isJPG && !isJPEG && !isPNG) {
                this.$message.error("上传图片只能是 JPEG/JPG/PNG 格式!");
            }
            if (!isLt20MB) {
                this.$message.error("单张图片大小不能超过 20MB!");
            }
            
            console.log((isJPEG || isJPG || isPNG) && isLt20MB);
            return (isJPEG || isJPG || isPNG) && isLt20MB;
        },
        async uploadImage(file,key) {
            console.log(file)
            let rest = await qiniuPolicy()
            const uploader = new QiniuUploader({
                bucket: 'qiyu-temp-store',
                token: rest.data,
                domain: 'https://storage.iqiyu.net'
            });
            uploader.upload(
                file.raw,
                    (percent) => console.log(`上传进度: ${percent}%`),
                    (err) => {
                        this.$message.error("导入失败" + err);
                    },
                    (result) => {
                        console.log('上传成功:', result)
                        console.log(this.form,key)
                        // 校验固定手机号码标识
                        this.form[key] = result
                        this.$set(this.form, key, result);
                    }
                );
        },
    },
};
</script>

<style lang="less">
@import "../../style/mixin.less";
</style>
<style lang="scss" scoped>
@import "@/assets/styles/base.scss";
</style>

<style lang="scss">
.my-label {
    color: red !important;
}

.el-table .warning-row {
    background: oldlace !important;
}

.el-table .success-row {
    background: #f0f9eb !important;
}

.step_3 {
    height: 300px;
    width: auto;
    text-align: center;
}

.btn_box button {
    margin: 10px;
}

.extra_title {
    margin: 10px;
    text-align: center;
    background-color: #f8f8f9;
    padding: 10px;
    margin: 20px;
    border-radius: 10px;
}

.action-box2 {
    margin-top: 20px;
}

.el-upload__tip {
    color: red;
}

.companyExtraInfo {
    /* border-bottom: 5px solid #409EFF; */
    border-radius: 10px;
    padding: 10px;
    background-color: #f8f8f9;
    margin: 20px;
}

.companyExtraInfo2 {
    margin-top: 20px;
}

::v-deep.el-radio input[aria-hidden="true"] {
    display: none !important;
}

::v-deep.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled)
    .el-radio__inner {
    box-shadow: none !important;
}

::v-deep .el-tooltip__popper {
    max-width: 300px !important;
    font-size: 20px;
    /* 设置你想要的宽度 */
}
.avatar-uploader .avatar {
    width: 120px !important;
    height: 120px;
    display: block;
    object-fit: cover;
    border-radius: 0;
}
.avatar-uploader .el-upload {
    border: 1px dashed #C0C4CC;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar-uploader .el-upload:hover {
    border-color: #C0C4CC;
}

.el-icon-plus.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
<style>
.el-descriptions-item__label {
    width: 120px;
}
</style>
