var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container home" }, [
    _c(
      "div",
      { staticClass: "card-box wrap" },
      [
        _c("vs-card", {
          staticClass: "item",
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("h3", [_vm._v("用户手册")])]
              },
              proxy: true,
            },
            {
              key: "img",
              fn: function () {
                return [
                  _c("img", {
                    attrs: {
                      src: require("../assets/images/guide.jpg"),
                      alt: "",
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "text",
              fn: function () {
                return [
                  _c("p", [
                    _vm._v(
                      " 无论何时，您遇到的绝大部分问题都可以在这里找到答案。如仍未解决，请联系客服人员, 客服微信号: mando717 "
                    ),
                  ]),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.yuque.com/iqiyu/saas",
                        target: "_blank",
                      },
                    },
                    [_vm._v("点击查看" + _vm._s(_vm.$webTitle) + "用户手册")]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "interactions",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        }),
        _c("vs-card", {
          staticClass: "item",
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("h3", [_vm._v("使用问题反馈")])]
              },
              proxy: true,
            },
            {
              key: "img",
              fn: function () {
                return [
                  _c("img", {
                    attrs: { src: require("../assets/logo/kefu.png"), alt: "" },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "text",
              fn: function () {
                return [
                  _c("p", [
                    _vm._v(
                      " 您的意见和建议对" +
                        _vm._s(_vm.$webTitle) +
                        "来讲都非常重要！如发现问题请告知我们，让我们一起变得更好。 "
                    ),
                  ]),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://docs.qq.com/sheet/DZHdaRU1IVVB3UmNX?tab=BB08J2",
                        target: "_blank",
                      },
                    },
                    [_vm._v("点击查看表格，填写问题反馈")]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "interactions",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        }),
        _c("vs-card", {
          staticClass: "item",
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("h3", [
                    _vm._v('"' + _vm._s(_vm.$webTitle) + '获客" 新功能免费用'),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "img",
              fn: function () {
                return [
                  _c("img", {
                    attrs: {
                      src: require("../assets/images/data.png"),
                      alt: "",
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "text",
              fn: function () {
                return [
                  _c("p"),
                  _c("p", [
                    _vm._v(
                      " 即将推出精准拓客功能“" +
                        _vm._s(_vm.$webTitle) +
                        "获客”。通过大模型技术获取互联网上全维度高精准的全国企业信息，经数据清洗提升资源精确度，加速成交。可在左侧”" +
                        _vm._s(_vm.$webTitle) +
                        "获客“菜单中免费领取线索（每天可领取300条/公司）。 "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "interactions",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        }),
        _c("vs-card", {
          staticClass: "item",
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("h3", [_vm._v("引荐客户，获得奖励")])]
              },
              proxy: true,
            },
            {
              key: "img",
              fn: function () {
                return [
                  _c("img", {
                    attrs: {
                      src: require("../assets/logo/qrcode.jpeg"),
                      alt: "",
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "text",
              fn: function () {
                return [
                  _c("p"),
                  _c("p", [
                    _vm._v(
                      " 如果您认为" +
                        _vm._s(_vm.$webTitle) +
                        "软件好用（包含 外呼系统，CRM客户管理系统，大数据拓客系统，百度口袋资料库），请将" +
                        _vm._s(_vm.$webTitle) +
                        "推荐给您身边的公司朋友，在他们试用且成功购买后，您将获得返利优惠奖励。 "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "interactions",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }